<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <!-- CAMPO NOMBRE MONEDA -->
            <v-col cols="12" md="7">
              <v-text-field
                v-model="form.moneda_descripcion"
                name="Moneda"
                label="Descripción de la Moneda"
                placeholder="Escriba el nombre de la moneda..."
                id="id_moneda"
                :rules="[
                  (v) => !!v || 'El campo nombre de moneda es requerido.',
                ]"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO CODIGO -->
            <v-col cols="12" md="5">
              <v-text-field
                v-model="form.codigo"
                label="Código"
                :rules="[(v) => !!v || 'El campo código es requerido.']"
                placeholder="Escriba el código..."
              ></v-text-field>
            </v-col>

            <!-- CAMPO SIMBOLO -->
            <v-col cols="12" md="7">
              <v-text-field
                v-model="form.simbolo"
                name="simbolo"
                label="Nuevo símbolo"
                placeholder="Escriba un símbolo para la moneda..."
                id="id_moneda_simbolo"
                :rules="[(v) => !!v || 'El campo símbolo es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO DECIMALES -->
            <v-col cols="12" md="5">
              <v-text-field
                v-model="form.numero_decimales"
                :rules="[(v) => !!v || 'El campo decimales es requerido.']"
                name="NumeroDecimales"
                label="Numero de decimales"
                placeholder="Escriba numero de decimales..."
                id="id_moneda_numero_decimales"
                type="number"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO PAISES -->
            <v-col cols="12">
              <v-autocomplete
                v-model="form.pais"
                :items="paises"
                item-text="pais_descripcion"
                item-value="pais"
                item-disabled="eliminado"
                label="Paises"
                :rules="[
                  (v) => v.length > 0 || 'Debe seleccionar al menos 1 pais.',
                ]"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
            <!-- CAMPO ALINEACION -->
            <v-col cols="12">
              <div>Alineación</div>
              <v-radio-group v-model="form.alineacion" mandatory>
                <v-radio
                  v-for="(option, i) in alineacionDeducible"
                  :key="i"
                  :label="option.label"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">{{
          labels.submit
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "MonedaModalForm",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de moneda",
      form: {
        moneda_descripcion: "",
        simbolo: "",
        codigo: "",
        numero_decimales: 2,
        pais: [],
        alineacion: "",
      },
      paises: [],
      loadingForm: false,
      loadingPaises: false,
    };
  },
  methods: {
    ...mapActions("NewProduct", ["CrearMoneda", "UpdateMoneda", "getPaises"]),

    async setup() {
      this.loadingPaises = true;
      try {
        const response = await this.getPaises();
        this.paises = response;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loadingPaises = false;
    },

    async send() {
      this.loadingForm = true;
      const formData = { ...this.form };
      let method = this.CrearMoneda;
      if (this.formData) {
        method = this.UpdateMoneda;
        formData.id = this.formData.moneda;
      }
      try {
        const response = await method(formData);
        this.snackbar(response.info, "success");
        this.$emit("submit");
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loadingForm = false;
    },
  },
  computed: {
    alineacionDeducible() {
      return [
        {
          label: "Izquierda",
          value: "izquierda",
        },
        {
          label: "Derecha",
          value: "derecha",
        },
      ];
    },
  },
};
</script>
