<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span v-if="valor == 'financiera'" class="text-h5 ml-3"
          >Financieras</span
        >
        <span v-else-if="valor == 'concesionario_canal'" class="text-h5 ml-3"
          >Concesionario Canal</span
        >
        <span v-else class="text-h5 ml-3">Otro</span>
        <v-spacer></v-spacer>
        <v-icon
          @click.self="(normalClose = 1), closebtn(false)"
          class="global-icon7"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider class="pa-1"></v-divider>

      <v-card-text class="mt-2 pb-0">
        <v-container>
          <v-row no-gutters>
            <v-form ref="formulario" class="pa-0 ma-0">
              <v-col v-if="valor == 'financiera'" cols="12">
                <v-select
                  v-model="financiera"
                  :items="financieras"
                  item-text="financiera_descripcion"
                  item-value="financiera"
                  :rules="[rules.finRequired]"
                  outlined
                  class="tamaños-text"
                ></v-select>
              </v-col>
              <v-col v-else cols="12">
                <v-textarea
                  v-model="otro"
                  dense
                  auto-grow
                  :rules="[rules.otroRequired]"
                  outlined
                  class="tamaños-text"
                ></v-textarea>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider class="pa-1"></v-divider>

      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="(normalClose = 0), closebtn(false)">
          Cancelar
        </v-btn>
        <v-btn color="primary" @click="guardar()"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    valor: {
      type: String,
      default: "",
    },
    editOpt: {
      type: String,
      default: "",
    },
    normalOpt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rules: {
        finRequired: (value) => !!value || "Campo requerido.",
        otroRequired: (value) => !!value || "Campo requerido.",
      },
      dialog: true,
      titulo: "",
      ramo: "",
      codigo: "",
      showAler: false,
      financiera: "",
      financiera_selected: {},
      financieras: [],
      otro: "",
      normalClose: 0,
    };
  },
  methods: {
    ...mapActions("NewProduct", ["getFinancieras"]),

    closebtn(accion, finan, ot) {
      if (this.normalClose == 0) {
        if (this.$refs.formulario.validate()) {
          this.$emit("cerrarmodalOpt", accion, finan, ot);
        }
      } else {
        this.$emit("cerrarmodalOpt", accion, finan, ot);
      }
    },

    guardar() {
      if (this.$refs.formulario.validate()) {
        this.financiera_selected = this.financieras.find((e) => {
          return e.financiera === this.financiera;
        });
        this.closebtn(false, this.financiera_selected, this.otro);
        this.financiera = "";
        this.otro = "";
      }
    },
  },

  created() {
    this.getFinancieras().then((v) => {
      this.financieras = v;
      if (this.editOpt || this.normalOpt) {
        this.financiera = v.find((e) => {
          if (this.editOpt) {
            return e.financiera === this.editOpt;
          } else {
            return e.financiera === this.normalOpt;
          }
        });
      }
    });
    if (this.valor == "otro") {
      if (this.editOpt) {
        this.otro = this.editOpt;
      } else if (this.normalOpt) {
        this.otro = this.normalOpt;
      }
    } else {
      this.otro = "";
    }
  },
};
</script>

<style scoped>
.tamaños-text {
  width: 400px;
}
</style>
