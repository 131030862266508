<template>
  <div class="father-div">
    <v-container wrap class="position-relative">
      <v-img
        v-if="company && !mobile"
        :src="company.logo"
        max-width="150"
        height="65"
        contain
        class="product-selected-logo"
      ></v-img>
      <v-expansion-panels accordion focusable flat multiple v-model="panel">
        <v-expansion-panel
          v-for="(item, i) in categories"
          :key="i"
          class="mb-2"
          :id="`section-${item.ref}`"
        >
          <v-expansion-panel-header
            color="secondary text-h6 primary--text text--darken-4"
            v-show="!('show' in item) || item.show"
          >
            {{ item.text }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- TEXTOS -->
            <v-container wrap class="pa-2" v-if="item.id == 41">
              <v-alert v-if="showAler" outlined :type="typeAlert">
                {{ messageAlert }}
              </v-alert>
              <div class="mt-3">
                <!-- TIPOS DE DOCUMENTOS -->
                <v-row align="center">
                  <v-col cols="6">
                    <v-select
                      :items="tipoDeTextos"
                      :value="form.tipoDeTexto"
                      @change="handlerSelectTextType"
                      return-object
                      item-text="documento_descripcion"
                      item-value="id_emision"
                      outlined
                      label="Tipo de Documentos"
                      placeholder="Elegir tipo de Documento"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-img
                      :src="form.companies ? form.companies.url : ''"
                      max-width="165px"
                      max-height="120px"
                    ></v-img>
                  </v-col>
                </v-row>
                <!-- PLANTILLAS -->
                <!-- <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">*</th>
                        <th class="text-left">Plantillas Guardadas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in textos"
                        :key="i"
                        @click="verTexto(item)"
                        class="selectable"
                      >
                        <td>
                          <v-icon>mdi-folder-eye</v-icon>
                        </td>
                        <td>{{ item.tipo_texto }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table> -->

                <!-- TEXTBOX -->
                <div
                  class="secondary pa-2 primary--text text--darken-4 d-flex align-center"
                >
                  <div class="text-h6">Ingresa el texto aquí</div>
                  <v-spacer></v-spacer>
                  <v-text-field
                    class="white"
                    clearable
                    dense
                    filled
                    label="Buscador de palabras..."
                    @click:append="handlerSearchWords"
                    v-model="search"
                    outlined
                    append-icon="mdi-magnify"
                    hide-details=""
                  ></v-text-field>
                </div>
                <vue-editor
                  v-model="form.text"
                  :editor-toolbar="customToolbar"
                  id="plantilla-editor"
                />
                <div class="secondary pa-2 primary--text text--darken-4 d-flex">
                  <!-- <v-icon>mdi-folder-eye</v-icon> -->
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    @click="textoCampaña()"
                    :loading="textLoading"
                    >Guardar texto</v-btn
                  >
                </div>
                <p class="caption mt-3">
                  <b>Info:</b> Para agregar o modificar los textos, primero
                  seleccione uno de los tipos de <b>Documentos Emitidos</b> y a
                  continuación proceda a redactar o pegar la información en el
                  campo de texto.
                </p>
                <p class="caption">
                  Cada texto está relaccionado con un tipo de Documento Emitido,
                  por lo que solo se puede crear un texto por tipo de documento.
                  <br />
                  Seleccione los tipos de documentos para visualizar los textos
                  ya creados.
                </p>
              </div>
            </v-container>

            <!-- POLIZA -->
            <v-container wrap class="pa-2" v-else-if="item.id == 42">
              <v-autocomplete
                :items="Poliza"
                item-text="poliza_descripcion"
                :loading="loading.polizas"
                v-model="form.poliza"
                outlined
                label="Tipo de Póliza"
                class="mt-6"
                return-object
              ></v-autocomplete>
            </v-container>

            <!-- CONTRATANTE -->
            <v-container wrap class="pa-2" v-else-if="item.id == 43">
              <v-row>
                <v-col class="mt-6">
                  <v-select
                    :items="Contratante"
                    item-text="tipo_contratante_descripcion"
                    v-model="form.contratante"
                    outlined
                    label="Tipo de Contratante"
                    class="mt-6"
                    return-object
                  ></v-select> </v-col
              ></v-row>
              <v-fade-transition mode="out-in">
                <v-row
                  v-if="
                    form.contratante &&
                    form.contratante.tipo_contratante_descripcion == 'Otro'
                  "
                >
                  <v-col>
                    <v-text-field
                      v-model="form.nombreContratante"
                      name="nombre-cobertura"
                      :label="nombreContratante.label"
                      :placeholder="nombreContratante.text"
                      id="id_nombre_contratante"
                      :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="form.rutContratante"
                      name="rut-comercial"
                      :label="rutContratante.label"
                      :placeholder="rutContratante.text"
                      id="id_rut_contratante"
                      :rules="rules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-else-if="
                    form.contratante &&
                    form.contratante.tipo_contratante_descripcion ==
                      'Financiera'
                  "
                >
                  <v-col>
                    <v-select
                      :items="Financiera"
                      item-text="financiera_descripcion"
                      v-model="form.financiera"
                      outlined
                      label="Financiera"
                      return-object
                    ></v-select> </v-col
                ></v-row>
                <v-row
                  v-else-if="
                    form.contratante &&
                    form.contratante.tipo_contratante_descripcion == 'Convenio'
                  "
                >
                  <v-col>
                    <v-select
                      :items="Convenios"
                      item-text="canal_descripcion"
                      v-model="form.convenios"
                      outlined
                      label="Convenio"
                      return-object
                    ></v-select> </v-col
                ></v-row>
              </v-fade-transition>
            </v-container>

            <!-- NEMOTECNICO -->
            <v-container wrap class="pa-2" v-else-if="item.id == 44">
              <v-fade-transition>
                <div v-if="!nemoLoading">
                  <v-checkbox
                    v-model="form.nemotecnico_vigencia"
                    :label="'Nemotecnico por vigencia'"
                  ></v-checkbox>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="font-weight-black"
                            v-show="form.nemotecnico_vigencia"
                          >
                            Vigencia
                          </th>
                          <th
                            v-for="(n, i) in nemotecnico.header"
                            :key="i"
                            class="font-weight-medium"
                          >
                            {{ n.plan_descripcion }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, i) in nemotecnico.body"
                          :key="i"
                          v-show="!(!form.nemotecnico_vigencia && i > 0)"
                        >
                          <td
                            class="caption font-weight-medium grey--text text--darken-2"
                            v-show="form.nemotecnico_vigencia"
                          >
                            {{ item.text }}
                          </td>
                          <td
                            v-for="subitem in item.list"
                            :key="subitem.ref"
                            class="font-weight-medium"
                          >
                            <v-text-field
                              v-model="subitem.value"
                              @input="handlerNemoChange(subitem)"
                            ></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else class="pt-8 px-6 text-center">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>
            </v-container>

            <!-- TARIFAS -->
            <v-container wrap class="px-2 py-6" v-else-if="item.id == 45">
              <v-fade-transition mode="out-in">
                <v-select
                  v-if="tarifas.length > 0 && !tarifasLoading"
                  :items="tarifas"
                  v-model="form.tarifa"
                  item-text="tarifa_descripcion"
                  item-value="tarifa"
                  outlined
                  label="Tipo de Tarifa"
                  return-object
                  :loading="tarifasLoading"
                ></v-select>
                <v-alert v-else outlined type="warning">
                  La compañia seleccionada no tiene Tarifas configuradas
                </v-alert>
              </v-fade-transition>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="timeout"
      :right="x === 'right'"
      :top="y === 'top'"
      :color="color_message"
    >
      <v-icon class="global-icon7 mr-5">{{ icon }}</v-icon>
      {{ text_Menssage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Variable para definir actualizar el formulario",
    },
    producto: {
      type: [Object, String],
      default: null,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueEditor,
    // btnicon
  },
  data() {
    return {
      search: "",
      form: {
        tipoDeTexto: "",
        plantilla: "",
        text: "",
        poliza: {},
        nemotecnico_vigencia: false,
        nemotecnico: [],
        tarifa: {},
        planes: [],
        editar: false,
        rutContratante: "",
        nombreContratante: "",
        tipoDeTextoGuardado: "",
        contratante: null,
        financiera: null,
        convenios: null,
      },

      panel: [],
      Poliza: [],

      textos: [],
      tipoDeTextos: [],
      Contratante: [],
      Financiera: [],
      Convenios: [],

      url: "",
      tipo_plantilla: "",
      tipo_texto: "",
      tipo_texto_id: "",
      typeAlert: "",
      messageAlert: "",

      showAler: false,
      banderaCompañia: false,
      textLoading: false,
      tarifasLoading: false,
      loading: {
        polizas: false,
      },

      nemoLoading: false,
      nemotecnico: {
        header: [],
        body: [],
      },

      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        //   [{ font: [] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote" /* 'code-block' */],
        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction
        // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [
          { align: null },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link", "image"],
        ["clean"], // remove formatting button
      ],

      nombreContratante: {
        label: "Nombre contratante",
        text: "Escribe el nombre del contratante",
        disabled: false,
      },
      rutContratante: {
        label: "Rut del contratante",
        text: "Escribe el rut del contratante",
        disabled: false,
      },

      rules: [(value) => !!value || "Required."],

      multiLine: true,
      snackbar: false,
      text_Menssage: "",
      y: "top",
      x: "right",
      timeout: 2000,
      color_message: "",
      icon: "",
    };
  },
  async created() {
    let counter = 0;
    this.categories.forEach(() => {
      this.panel.push(counter);
      counter++;
    });

    this.$store.commit("statePiner", true);
    if (this.editMode) {
      const compania_tarifas = this.producto.compania_tarifas;

      this.getByIDPolizas(compania_tarifas.id_poliza);
      this.getByIDContratante(compania_tarifas.contratante.tipo_contratante);
      this.getByIDFinanciera(compania_tarifas.contratante.id_contratante);
      this.getByIDConvenio(compania_tarifas.contratante.id_contratante);

      this.form.tarifa = compania_tarifas.tarifas;

      var tipoText = this.producto.atributos.documentos_emision;
      this.form.editar = true;
      this.form.rutContratante = compania_tarifas.contratante.rut_contratante;
      this.form.nombreContratante = compania_tarifas.contratante.contratante;

      this.form.nemotecnico_vigencia =
        this.producto.compania_tarifas.nemotecnico_vigencia;
      try {
        await this.GetTextosGuardados(this.producto.id);
      } catch (e) {
        console.error(e);
      }
    }
    this.getPoliza();
    this.getContratante();
    this.getFinancieraContra();
    this.getConveniosContra();
    this.getTarifas();
    this.setNemotecnicos();
    await this.textos_del_Producto(
      this.getDocEmit.length > 0
        ? this.getDocEmit
        : this.editMode
        ? tipoText
        : []
    );
  },

  computed: {
    ...mapGetters("NewProduct", [
      "company",
      "nemotecnicos",
      "vigencias",
      "planes",
      "getProductTypeId",
      "tarifas",
    ]),
    getProductoId() {
      return this.$store.getters.getProductoId;
    },
    getDocEmit() {
      return this.$store.getters.getDocEmit;
    },
  },

  methods: {
    ...mapActions("NewProduct", [
      "getPolizas",
      "getTipoTarifas",
      "UpdataCompañiatextos",
      "getDocEmicionAll",
      "getIdComañia",
      "getIdPolizas",
      "getIdContratante",
      "getIdFinanciera",
      "getIdConvenios",
      "GetTextos",
      "getIDViPlan",
      "getTextosIDproductIDdocument",
      "getTipoContratante",
      "getFinancieras",
      "getConveniosContratante",
      "UpdateNemotecnico",

      "REQUEST_NEMOTECNICOS",
    ]),

    //TEXTOS
    async textoCampaña() {
      this.textLoading = true;
      const formData = {
        id_documento_emision: this.form.tipoDeTexto,
        tipo_texto: this.tipo_texto,
        plantilla: this.tipo_plantilla,
        texto: this.form.text,
        id_producto: this.editMode
          ? this.producto.id
          : this.$store.getters.getProductoId.id,
        id_compania: this.company.id,
      };
      if (this.tipo_texto_id && this.tipo_texto_id !== "") {
        formData._id = this.tipo_texto_id;
      }
      const res = await this.UpdataCompañiatextos(formData);
      if (res.status >= 200 && res.status < 300) {
        const textoResult = res.data.data.texto;
        this.GetTextosGuardados(textoResult.id_producto);
        this.form.tipoDeTextoGuardado = textoResult.texto;
        this.tipo_texto_id = textoResult._id;
        this.banderaCompañia = true;
        this.snackbar = true;
        this.color_message = "success";
        this.text_Menssage = res.data.data.lista_mensaje[0].mensaje;
        this.icon = "mdi-checkbox-marked-circle";
      }
      if (res.status == 422 || res.status >= 400) {
        this.showAler = true;
        try {
          this.messageAlert = res.data.error;
        } catch {
          this.messageAlert =
            "Ha ocurrido un error al intentar guardar un texto.";
        }
        this.typeAlert = "error";
        setTimeout(() => {
          this.showAler = false;
        }, 5000);
      }
      this.textLoading = false;
    },

    async GetTextosGuardados(id) {
      var data = {
        id_producto: id,
      };
      const resp = await this.GetTextos(data);
      if (resp.status >= 200 && resp.status < 300) {
        this.textos = resp.data.data.result;
        if (resp.data.data.result.length > 0) {
          this.form.tipoDeTextoGuardado = resp.data.data.result[0].texto;
        }
      }
      if (resp.status === 422 || resp.status >= 400) {
        this.showAler = true;
        this.messageAlert = resp.data.error
          ? resp.data.error
          : resp.data.data.lista_mensaje[0].mensaje;
        this.typeAlert = "info";
        setTimeout(() => {
          this.showAler = false;
        }, 6000);
      }
    },

    textos_del_Producto(tipoText) {
      let data;
      for (var i in tipoText) {
        data = {
          _id: tipoText[i]._id || null,
          documento_descripcion:
            tipoText[i].documento || tipoText[i].documento_descripcion,
          id_emision: tipoText[i].id_emision || tipoText[i]._id,
        };
        if (i === 0 || i === "0") {
          this.handlerSelectTextType(data);
        }
        this.tipoDeTextos.push(data);
      }
    },

    // async verTexto(item) {
    //   var data = {
    //     id_producto: item.id_producto,
    //     id_documento_emision: item.id_documento_emision,
    //   };
    //   await this.getTextosIDproductIDdocument(data).then((resp) => {
    //     this.tipoDeTextos.find((a) => {
    //       if (
    //         a.id_emision === resp.id_documento_emision ||
    //         a._id === resp.id_documento_emision
    //       ) {
    //         this.form.tipoDeTexto = a._id;
    //       }
    //     });

    //     this.form.text = resp.texto;
    //   });
    // },

    //LISTAR DOCUMENTOS
    // async documentosEmitidos() {
    //   var docSelect = this.$store.getters.getDocEmit;
    //   await this.getDocEmicionAll().then((resp) => {
    //     resp.map((r) => {
    //       for (let i = 0; i <= docSelect.length - 1; i++) {
    //         if (r._id === docSelect[i]._id) {
    //           this.tipoDeTextos.push(r);
    //         }
    //       }
    //     });
    //   });
    // },

    //POLIZAS
    getPoliza() {
      this.loading.polizas = true;
      this.getPolizas()
        .then((response) => {
          this.Poliza = response;
        })
        .finally(() => {
          this.loading.polizas = false;
        });
    },
    async getByIDPolizas(id) {
      if (id !== undefined) {
        await this.getIdPolizas(id).then((resp) => {
          this.form.poliza = resp.data.data.result;
        });
      } else {
        this.getPoliza();
      }
    },

    //GET Contratante
    async getContratante() {
      await this.getTipoContratante().then((resp) => {
        this.Contratante = resp;
      });
    },

    async getByIDContratante(id) {
      if (id !== undefined) {
        await this.getIdContratante(id).then((resp) => {
          this.form.contratante = resp.data.data.result[0];
        });
      } else {
        this.getContratante();
      }
    },

    async getFinancieraContra() {
      await this.getFinancieras().then((resp) => {
        this.Financiera = resp;
      });
    },

    async getByIDFinanciera(id) {
      if (id !== undefined) {
        await this.getIdFinanciera(id).then((resp) => {
          this.form.financiera = resp.data.data.result[0];
        });
      } else {
        this.getFinancieraContra();
      }
    },

    async getConveniosContra() {
      await this.getConveniosContratante().then((resp) => {
        this.Convenios = resp.result;
      });
    },

    async getByIDConvenio(id) {
      if (id !== undefined) {
        await this.getIdConvenios(id).then((resp) => {
          this.form.convenios = resp.data.data.result;
        });
      } else {
        this.getConveniosContra();
      }
    },

    //GET TARIFAS
    async getTarifas() {
      this.tarifasLoading = true;
      if (this.company && this.company.id_empresa) {
        const params = {
          id_empresa: this.company.id_empresa || "",
        };
        await this.getTipoTarifas(params)
          .catch((message) => {
            this.messageAlert = message;
            this.typeAlert = "warning";
            this.showAler = true;
            setTimeout(() => {
              this.showAler = false;
            }, 6000);
          })
          .finally(() => (this.tarifasLoading = false));
      } else {
        this.tarifasLoading = false;
      }
    },

    //NEMOTECNICO

    async setNemotecnicos() {
      this.nemoLoading = true;
      if (this.company && this.company.id && this.company.id !== "") {
        //CREAR LA DATA PARA EL NEMOTECNICO
        const planes = [],
          vigencias = [];

        // Listado de planes
        this.planes.forEach((e) => {
          planes.push(e.plan);
        });

        //Listado de vigencias
        this.vigencias.forEach((e) => {
          vigencias.push(e.id_vigencia || e._id);
        });

        // Se establece la data
        const formData = {
          tipo_producto: this.getProductTypeId, // Id del tipo de producto seleccionado (Paso 1)
          compania: this.company.id, // Id de la compania seleccionada (Paso 1)
          ramo: this.$store.getters.getRamoId, // Id del ramo seleccionado (Paso 1)
          planes: planes, // Ids de planes seleccionados (Paso 2)
          vigencias: vigencias, // Ids de las vigencias seleccionadas (Paso 3)
        };
        const payload = {
          id:
            this.editMode && this.producto
              ? this.producto.id
              : this.$store.getters.getProductoId.id,
          data: formData,
        };

        await this.REQUEST_NEMOTECNICOS(payload)
          .then(() => {
            this.form.nemotecnico = [];
            this.nemotecnico.body = [];
            this.nemotecnico.header = [];
            const header = [],
              body = [];
            this.nemotecnicos.forEach((e, index) => {
              e.ref = index;
              this.form.nemotecnico.push(e);
              // Set header
              var checkHeader = header.find((x) => x.plan === e.plan);
              if (!checkHeader) {
                header.push({
                  plan_descripcion: e.plan_descripcion,
                  plan: e.plan,
                });
                header.sort(this.handlerSortByPlan());
              }
              // Item
              var item = {
                ref: index,
                value: e.subproducto,
                plan: e.plan,
                plan_descripcion: e.plan_descripcion,
              };
              // Set body
              let checkBody = body.find((x) => x.value === e.vigencia);
              if (checkBody) {
                checkBody.list.push(item);
                checkBody.list.sort(this.handlerSortByPlan());
              } else {
                body.push({
                  value: e.vigencia,
                  text: e.vigencia_descripcion,
                  count: e.vigencia_meses,
                  list: [item],
                });
              }
            });
            body.sort((a, b) => a.count - b.count);
            this.nemotecnico.header = header;
            this.nemotecnico.body = body;
          })
          .finally(() => (this.nemoLoading = false));
      }
    },

    handlerNemoChange(item) {
      let itemChanged = this.form.nemotecnico.find((e) => e.ref === item.ref);
      if (itemChanged) {
        itemChanged.subproducto = item.value;
      }
    },

    handlerSortByPlan() {
      return (a, b) => {
        return a.plan_descripcion - b.plan_descripcion;
      };
    },

    handlerSelectTextType(doc) {
      this.form.tipoDeTexto = doc.id_emision || "";
      const selected = this.textos.find(
        (e) => e.id_documento_emision === (doc.id_emision || doc._id)
      );
      this.tipo_plantilla = selected
        ? selected.plantilla
        : doc.documento_descripcion;
      this.tipo_texto = selected
        ? selected.tipo_texto
        : doc.documento_descripcion;
      this.tipo_texto_id = selected ? selected._id : "";
      this.form.text = selected ? selected.texto : "";
    },

    handlerSearchWords() {
      if (this.search) {
        self.find(this.search, false, false);
      }
    },
  },
};
</script>
