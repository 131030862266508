<template>
  <!-- LISTADO DE TEMAS -->
  <v-list :class="listClasses">
    <v-subheader v-if="headerText !== ''">{{ headerText }}</v-subheader>
    <v-list-item
      v-for="item in items"
      :key="item._id"
      link
      :disabled="item.eliminado"
      @click="$emit('select-item', item)"
      two-line
    >
      <v-list-item-icon align-self="center">
        <v-icon>mdi-shield-check-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          item.corredor_descripcion
        }}</v-list-item-title>
        <v-list-item-subtitle
          >{{ item.tipo_documento }}
          {{ item.numero_documento }}</v-list-item-subtitle
        >
      </v-list-item-content>

      <!-- <v-list-item-avatar>
    <v-img :src="''"></v-img>
    </v-list-item-avatar> -->
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "InsuranceBrokerList",
  props: {
    headerText: {
      type: String,
      default: "",
    },
    listClasses: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
