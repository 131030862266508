<template>
  <v-navigation-drawer app right>
    <v-list dense class="py-0">
      <template v-for="(iteracion, j) in items">
        <v-subheader
          :key="`t-${j}`"
          class="primary--text text--darken-4 font-weight-bold"
          >{{ iteracion.title }}</v-subheader
        >
        <v-list-item-group
          v-model="selectedItem"
          :key="j"
          class="navbar-boxes"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in iteracion.list"
            :key="i"
            :disabled="handlerCheckDisabled(item) || item.disabled"
            :value="item.ref"
            @click="goTo(item.ref)"
          >
            <!-- <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon> -->

            <v-list-item-content>
              <v-list-item-title
                :class="item.ref"
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div
          :key="`d-${j}`"
          v-if="
            j != items.length - 1 && $vuetify.breakpoint.name != 'smAndDown'
          "
        ></div>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    selectedItem: "1-1",
  }),
  methods: {
    goTo(item) {
      this.$emit("goTo", item);
    },
    handlerCheckDisabled(item) {
      if ("ref" in item) {
        const refArray = item.ref.split("-");
        const step = parseInt(refArray[0]);
        return step > this.progress;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.custom-drawer {
  position: sticky;
  top: 20px;
}
.navbar-boxes {
  border: 1px rgba(0, 0, 0, 0.08) solid;
}
</style>
