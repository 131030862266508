<template>
  <div class="father-div">
    <v-container wrap class="position-relative">
      <v-img
        v-if="company && !mobile"
        :src="company.logo"
        max-width="180"
        contain
        height="65"
        class="product-selected-logo"
      ></v-img>

      <v-expansion-panels accordion focusable flat multiple v-model="panel">
        <v-expansion-panel
          v-for="(item, i) in categories"
          :key="i"
          class="mb-2"
          :id="`section-${item.ref}`"
        >
          <v-divider></v-divider>
          <v-expansion-panel-header
            color="secondary text-h6 primary--text text--darken-4"
          >
            {{ item.text }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- DISTRIBUCION -->
            <v-container v-if="item.id == 21">
              <v-radio-group
                v-model="form.distribucion_prima"
                mandatory
                class="d-flex"
              >
                <v-radio
                  v-for="(option, i) in distribucion"
                  :key="i"
                  :label="option.label"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>

              <h4 class="subtitle-2 font-weight-medium">
                Detalle de la distribución:
                <small
                  :class="
                    form.distribucion_prima > 0 ? 'primary--text' : 'grey--text'
                  "
                  >{{
                    distribucion.find(
                      (e) => e.value === form.distribucion_prima
                    ).label
                  }}</small
                >
              </h4>
              <div class="caption">
                <p v-if="form.distribucion_prima === 1">
                  El monto de distribución de la prima es porcentual (%). <br />
                  La suma total de todos los montos de distribuciones para las
                  coberturas debe dar como resultado 100%.
                </p>
                <ul v-else-if="form.distribucion_prima === 2">
                  El monto de distribución de la prima es fijo.
                  <br />
                  <li>Una cobertura debe ser declarada como la Principal</li>
                  <li>
                    Se puede especificar la cobertura principal en el campo de
                    monto de distribución con la letra "P"
                  </li>
                  <li>Solo puede haber una cobertura principal.</li>
                </ul>
                <span v-else
                  >El monto de la distribución de la prima no aplica para las
                  coberturas de este producto.</span
                >
              </div>
            </v-container>

            <!-- TIPO EXCLUSIVIDAD -->
            <v-container v-if="item.id == 25">
              <v-fade-transition mode="out-in">
                <v-radio-group
                  v-if="!exclusivityLoading && exclusividades.length > 0"
                  v-model="exclusivitySelected"
                  mandatory
                  class="d-flex"
                  @change="handlerChangeExclusivity"
                >
                  <v-radio
                    v-for="(option, i) in exclusividades"
                    :key="i"
                    :label="option.exclusividad_descripcion"
                    :value="option.exclusividad"
                  ></v-radio>
                </v-radio-group>
                <p
                  class="caption primary--text text--darken-4"
                  v-else-if="!exclusivityLoading"
                >
                  <v-icon>mdi-information-outline</v-icon> No se ha encontrado
                  alguna exclusividad.
                </p>
                <div v-else class="mt-6">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>
            </v-container>

            <!-- COBERTURAS -->
            <v-container wrap class="pa-2" v-else-if="item.id == 22">
              <v-autocomplete
                :items="Coberturas"
                item-text="titulo"
                :item-value="['id_cobertura', 'cobertura', '_id']"
                clearable
                multiple
                label="Coberturas"
                placeholder="Seleccionar coberturas..."
                return-object
                :value="form.coberturas"
                @change="autocompleteCobertura"
                append-outer-icon="mdi-plus-circle-outline"
                :loading="loadingCoberturas"
                @click:append-outer="state_modal_cobertura = true"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.titulo }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+
                    {{
                      form.coberturas.length > 2
                        ? `otras ${form.coberturas.length - 1} coberturas.`
                        : "otra cobertura."
                    }})
                  </span>
                </template>
              </v-autocomplete>

              <v-row class="mt-6 align-center" dense>
                <v-col cols="1" md="1">
                  <h5 class="text-center">Nro°</h5>
                </v-col>
                <v-col cols="11" md="5">
                  <h5>Coberturas</h5>
                </v-col>

                <v-col cols="12" md="1" v-if="!$vuetify.breakpoint.smAndDown">
                  <h5>Deducible</h5>
                </v-col>

                <v-col cols="12" md="2" v-if="!$vuetify.breakpoint.smAndDown">
                  <h5 title="Monto asegurado">M. Asegurado</h5>
                </v-col>

                <v-col cols="12" md="2" v-if="!$vuetify.breakpoint.smAndDown">
                  <h5 title="Monto de cobertura">M. Distribución</h5>
                </v-col>

                <v-col cols="1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        x-small
                        fab
                        text
                        v-on="on"
                        v-bind="attrs"
                        @click="form.coberturas = []"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar todas las coberturas del listado</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <draggable
                v-model="form.coberturas"
                draggable=".item"
                @change="handlerMoveCobertura"
              >
                <div
                  v-for="(element, n) in form.coberturas"
                  :key="n"
                  class="item"
                >
                  <!-- Nombre de la cobertura -->
                  <v-row dense class="align-center">
                    <v-col
                      cols="1"
                      md="1"
                      align-self="center"
                      class="text-right pa-6"
                    >
                      {{ n }}
                    </v-col>
                    <v-col cols="11" md="5" align-self="center">
                      <span class="text-uppercase">{{ element.titulo }}</span>
                    </v-col>

                    <!-- Campo del deducible -->
                    <v-col cols="4" md="1">
                      <v-text-field
                        v-model="element.deducible"
                        class="mr-2 ml-2 mt-5 text-input-uppercase"
                        style="width: 88%"
                        name="deducible"
                        label="Deducible"
                        required
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 68 || event.charCode == 100"
                        @keyup="handlerUppercase(element, 'deducible')"
                      ></v-text-field>
                      <!-- Acepta numeros, decimales y la letra D -->
                    </v-col>

                    <!-- Campo del monto asegurado -->
                    <v-col cols="4" md="2">
                      <v-text-field
                        v-model="element.monto_asegurado"
                        class="mr-2 ml-2 mt-5"
                        style="width: 88%"
                        name="monto_asegurado"
                        label="Monto asegurado"
                        :rules="Rules"
                        required
                      ></v-text-field>
                    </v-col>

                    <!-- Campo del monto de distribucion -->
                    <v-col cols="4" md="2">
                      <v-text-field
                        v-model="element.monto_distribucion"
                        class="mr-2 ml-2 mt-5 text-input-uppercase"
                        style="width: 88%"
                        name="monto"
                        label="Monto distribución"
                        :rules="Rules"
                        required
                        :disabled="
                          form.distribucion_prima <= 0 ||
                          form.distribucion_prima === '0'
                        "
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 80 || event.charCode == 112"
                        @keyup="handlerUppercase(element, 'monto_distribucion')"
                      ></v-text-field>
                      <!-- Acepta numeros, decimales y la letra P -->
                    </v-col>

                    <v-col cols="1">
                      <v-btn
                        color="error"
                        text
                        x-small
                        fab
                        @click="removeCobertura(element)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </draggable>
            </v-container>

            <!-- PLANES -->
            <v-container wrap class="pa-2" v-else-if="item.id == 23">
              <v-fade-transition mode="out-in">
                <v-row class="mt-6" v-if="planes.length > 0 && !planesLoading">
                  <v-col
                    cols="12"
                    md="5"
                    v-for="(item, i) in planes"
                    :key="i"
                    class="d-flex"
                  >
                    <v-checkbox
                      v-model="form.plan"
                      class="mt-0"
                      :label="item.plan_descripcion"
                      :value="item"
                      hide-details
                      multiple
                      :disabled="item.eliminado"
                      @change="deducibleget()"
                    />
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      text
                      fab
                      v-if="!item.eliminado"
                      x-small
                      @click="deactivatePlan(item)"
                    >
                      <v-icon small> mdi-close </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <p
                  class="caption primary--text text--darken-4"
                  v-else-if="!planesLoading"
                >
                  <v-icon>mdi-information-outline</v-icon> No se han encontrado
                  planes creados.
                </p>
                <div v-else class="mt-6">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>
              <v-row class="align-center">
                <v-col cols="12" class="text-center">
                  <v-tooltip bottom color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        text
                        @click="valueRamo()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        Crear
                      </v-btn>
                    </template>
                    <span>Agregar un plan</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-else-if="item.id == 24">
              <v-fade-transition mode="out-in">
                <v-card
                  v-if="form.asistencias"
                  outlined
                  color="secondary lighten-1"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ form.asistencias.nombre_comercial }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        color="error"
                        text
                        fab
                        @click="form.asistencias = null"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
                <p v-else class="primary--text text--darken-4">
                  Puede seleccionar de forma opcional un producto adicional de
                  asistencia para el producto actual.
                </p>
              </v-fade-transition>
              <v-divider class="mt-3"></v-divider>
              <div v-if="product.loading" class="mb-3">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </div>
              <v-fade-transition mode="out-in">
                <v-list v-if="productos.length > 0">
                  <v-list-item-group v-model="form.asistencias" color="primary">
                    <v-list-item
                      v-for="(item, i) in productos"
                      :key="i"
                      :value="{
                        id_producto: item.id_producto,
                        nombre_comercial: item.nombre_comercial,
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre_comercial"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="`Solicitud Nro. ${item.id_solicitud}`"
                          class="text-right"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <p
                  class="caption primary--text text--darken-4 text-center"
                  v-else
                >
                  <v-icon>mdi-information-outline</v-icon> No se han encontrado
                  productos creados.
                </p>
              </v-fade-transition>
              <v-divider class="my-3"></v-divider>
              <v-pagination
                :value="product.current"
                circle
                :length="product.pages"
                :total-visible="7"
                @input="(product.current = $event), loadProductData()"
              ></v-pagination>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <ModalAlert
      v-if="state_modal_alert"
      @cerrarmodalAlert="cerrarmodalAlert"
      :titulomodalAlert="titulomodalAlert"
      :textoModal="textoModal"
    />
    <ModalCobertura
      v-model="state_modal_cobertura"
      @close="state_modal_cobertura = false"
      @submit="(state_modal_cobertura = false), getCoberturas()"
    />
    <ModalPlanes
      v-model="state_modal_planes"
      ref="formPlanes"
      @close="cerrarmodalPlanes"
      @submit="guardarModalPlanes"
      :ramoId="RamoModal"
    />
    <ModalDelet
      v-if="state_modal_delete"
      @cerrarmodalDelete="cerrarmodalDelete"
      :titulomodal="titulomodal"
      :step_id="step_id"
      :ids="ids"
      :dataDescription="dataDescription"
    />
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="timeout"
      :right="x === 'right'"
      :top="y === 'top'"
      :color="color_message"
    >
      <v-icon class="global-icon7 mr-5">{{ icon }}</v-icon>
      {{ text_Menssage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>

    <confirm-modal ref="confirm" />
  </div>
</template>

<script>
import ModalCobertura from "@/components/Modal/ModalProduct/Modal-Cobertura.vue";
import ModalPlanes from "@/components/Modal/ModalProduct/Modal-planes.vue";
import ModalAlert from "@/components/Modal/ModalAlert/Modal-alert.vue";
import ModalDelet from "@/components/Modal/ModalAlert/Modal-deletConf.vue";
import ConfirmModal from "@/components/Modal/ConfirmModal.vue";

import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Variable para definir actualizar el formulario",
    },
    producto: {
      type: [Object, String],
      default: null,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        distribucion_prima: 0,
        coberturas: [],
        plan: [],
        editar: false,
        asistencias: null,
        exclusividad: "",
      },

      Coberturas: [],
      planes: [],
      planesLoading: false,
      loadingCoberturas: false,

      exclusivitySelected: "",
      exclusivityLoading: false,

      state_modal_cobertura: false,
      state_modal_planes: false,
      state_modal_alert: false,
      state_modal_delete: false,

      idRamos: "",
      titulomodal: "",
      RamoModal: "",
      panel: [],

      Rules: [(value) => !!value || "Required."],
      // Products vars
      product: {
        loading: false,
        map: true,
        filter: "",
        current: 1,
        limit: 6,
        objects: 0,
        pages: 1,
      },
      // Snackbar vars
      multiLine: true,
      snackbar: false,
      text_Menssage: "",
      y: "top",
      x: "right",
      timeout: 2000,
      color_message: "",
      icon: "",
    };
  },
  async created() {
    this.$store.commit("statePiner", true);
    let counter = 0;
    this.categories.forEach(() => {
      this.panel.push(counter);
      counter++;
    });
    if (this.editMode) {
      const prod = this.producto;
      const coberturas_planes = prod.coberturas_planes;
      const planId = coberturas_planes.planes;

      this.autocompleteCobertura(coberturas_planes?.coberturas);
      this.form.distribucion_prima = coberturas_planes.distribucion_prima || 0;
      //Settear data de tipo de exclusividad
      this.form.exclusividad = coberturas_planes.exclusividad || null;
      this.exclusivitySelected = coberturas_planes.exclusividad
        ? coberturas_planes.exclusividad.exclusividad
        : "";
      this.form.coberturas.sort(this.sortCoberturas);
      this.form.editar = true;
      if (coberturas_planes.asistencias.length > 0) {
        this.form.asistencias = {
          id_producto: coberturas_planes.asistencias[0].id_producto,
          nombre_comercial: coberturas_planes.asistencias[0].nombre_comercial,
        };
      }

      this.planById(planId);
    }
    await this.setup();
    this.$store.commit("statePiner", false);
  },

  computed: {
    ...mapGetters("NewProduct", ["company", "productos", "exclusividades"]),

    distribucion() {
      return [
        {
          label: "No aplica",
          value: 0,
        },
        {
          label: "Porcentaje (%)",
          value: 1,
        },
        {
          label: "Valor fijo",
          value: 2,
        },
      ];
    },
  },
  methods: {
    ...mapActions("NewProduct", [
      "getCobertura",
      "getPlanes",
      "getIDViPlan",
      "getProducts",
      "getExclusivity",

      "deletePlan",
    ]),

    setup() {
      return new Promise((resolve) => {
        this.getCoberturas();
        this.getPlan();
        this.getExclusivityList();
        this.loadProductData();
        resolve("ok");
      });
    },
    cerrarmodalAlert() {
      this.state_modal_alert = false;
    },
    openModalAlert(tipo, texto) {
      this.titulomodalAlert = tipo;
      this.textoModal = texto;
      this.state_modal_alert = true;
    },
    // PLANES
    openModalPlanes(tipo = "Plan") {
      this.titulomodal = tipo;
      this.RamoModal = this.$store.getters.getRamoId;
      this.state_modal_planes = true;
    },
    guardarModalPlanes() {
      this.cerrarmodalPlanes();
      this.getPlan();
    },
    cerrarmodalPlanes() {
      this.state_modal_planes = false;
      this.$refs.formPlanes?.reset();
    },
    deactivatePlan(item = null) {
      this.$refs.confirm
        .show({
          title: "Desactivar plan",
          description:
            "Puede volver a activar este plan desde el mantenedor de Planes en cualquier momento.",
        })
        .then(async (answer) => {
          if (answer) {
            await this.deletePlan(item.plan);
            this.getPlan();
          }
        });
    },

    cerrarmodalDelete(action, message, color, icon) {
      if (!action) {
        this.state_modal_delete = false;
      } else {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = color;
        this.text_Menssage = message;
        this.icon = icon;
        this.getPlan();
      }
    },
    openDelete(tipo, step, id, data) {
      this.titulomodal = tipo;
      this.step_id = step;
      this.ids = id;
      this.dataDescription = data;
      this.state_modal_delete = true;
    },

    //COBERTURAS
    getCoberturas() {
      this.loadingCoberturas = true;
      this.getCobertura()
        .then((resp) => {
          if (resp.status == 200) {
            this.Coberturas = resp.data.data.coberturas;
          }
        })
        .finally(() => {
          this.loadingCoberturas = false;
        });
    },

    sortCoberturas(a, b) {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    },

    autocompleteCobertura(elements) {
      // Agregar coberturas a la lista de seleccionados
      for (const i of elements) {
        const itemId = i.cobertura || i.id_cobertura || i._id;
        const chack = this.form.coberturas.find(
          (e) => itemId === e.id_cobertura
        );
        if (!chack) {
          const item = {
            titulo: i.titulo,
            id_cobertura: itemId,
            monto: "",
            deducible: "",
            monto_asegurado: "",
            distribucion_prima: false,
            aplica: true,
            order: 100,
          };
          this.form.coberturas.push(item);
        }
      }
      // Remover Coberturas de la lista de seleccionados
      const newArray = [];
      this.form.coberturas.forEach((e) => {
        let checkedElement = elements.find(
          (x) => (x.cobertura || x.id_cobertura) == e.id_cobertura
        );
        if (checkedElement) {
          newArray.push(checkedElement);
        }
      });
      this.form.coberturas = newArray;
      this.handlerMoveCobertura();
    },
    disableSelect(item) {
      const chack = this.form.coberturas.find(
        (e) => item.id_cobertura === e.id_cobertura
      );
      return !chack;
    },

    removeCobertura(item) {
      this.form.coberturas = this.form.coberturas.filter(
        (e) =>
          (e.id_cobertura || e.cobertura) !==
          (item.id_cobertura || item.cobertura)
      );
    },

    handlerMoveCobertura() {
      this.form.coberturas.forEach((element, i) => {
        element.order = i;
      });
    },

    //PLAN DEDUCIBLE
    async getPlan() {
      this.planesLoading = true;
      const id = this.$store.getters.getRamoId;
      const resp = await this.getPlanes(id);
      if (resp.status >= 200 && resp.status < 300) {
        this.planes = resp.data.data.result;
      }
      if (resp.status >= 400 && resp.status < 600) {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = "error";
        this.text_Menssage = resp.data.error;
        this.icon = "mdi-cloud-alert";
      }
      this.planesLoading = false;
    },
    async planById(id) {
      const data = [];
      for (let i in id) {
        data.push(id[i]);
      }
      await this.getIDViPlan(data).then((resp) => {
        this.form.plan = resp;
        this.$store.commit("Deducible", this.form.plan);
      });
    },

    //VERIFICAR RAMO
    valueRamo() {
      if (this.planes == []) {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = "error";
        this.text_Menssage = "Debe seleccionar un ramo para agreagar un plan";
        this.icon = "mdi-cloud-alert";
      } else {
        this.openModalPlanes();
      }
    },

    handlerUppercase(item, key = "") {
      const selected = this.form.coberturas.find(
        (e) =>
          (e.id_cobertura || e.cobertura || e._id) ===
          (item.id_cobertura || item.cobertura || item._id)
      );
      if (selected && key && key !== "") {
        selected[key] = item[key].toUpperCase();
      }
    },

    //OBTENER DEDUCIBLE
    deducibleget() {
      const nemo = this.$store.getters.getNemotecnico;
      Object.assign(nemo, { deducible: this.form.plan });
      this.$store.commit("Deducible", this.form.plan);
    },

    // EXCLUSIVIDAD
    getExclusivityList() {
      this.exclusivityLoading = true;
      this.getExclusivity()
        .catch((message) => console.error(message))
        .finally(() => (this.exclusivityLoading = false));
    },
    handlerChangeExclusivity(val) {
      this.form.exclusividad =
        this.exclusividades.find((e) => e.exclusividad === val) || null;
    },

    // PRODUCT METHODS
    async loadProductData() {
      this.product.loading = true;
      this.product.excluir = this.producto.id;
      await this.getProducts(this.product)
        .then((response) => {
          if (response.pagination) {
            this.setProductPagination(response.pagination);
          }
        })
        .finally(() => (this.product.loading = false));
    },

    setProductPagination(data) {
      this.product.current = data.pagina;
      this.product.limit = data.limite;
      this.product.pages = data.paginas;
      this.product.objects = data.total;
    },
  },

  components: {
    ModalCobertura,
    ModalPlanes,
    ModalAlert,
    ModalDelet,
    draggable,
    ConfirmModal,
  },
};
</script>

<style scoped>
.item {
  cursor: row-resize;
}
.item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.father-div {
  min-height: 880px;
}
</style>
