<template>
  <div class="alert">
    <v-dialog v-model="dialog" width="50%">
      <div class="modlaAlert">
        <v-alert shaped prominent type="error" :timeout="200">
          <v-flex>
            <div class="d-flex">
              {{ texto }}
              <v-spacer></v-spacer>
              <v-icon @click.self="closebtn()" class="global-icon7 mr-5"
                >mdi-close</v-icon
              >
            </div>
          </v-flex>
        </v-alert>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import Button from "@/components/Button/Btn-Principal";
import { mapActions } from "vuex";

export default {
  props: ["form", "datamodal", "titulomodalAlert", "textoModal"],
  components: {
    // Button,
  },
  data() {
    return {
      dialog: true,
      titulo: "",
      idRamo: "",
      texto: "",

      dialog_input: {
        label: "Creación",
        text: "",
        disabled: false,
      },

      materia: "",
    };
  },
  created() {
    console.log(this.textoModal);
  },
  methods: {
    ...mapActions("NewProduct", ["CrearMateria"]),
    closebtn() {
      this.$emit("cerrarmodalAlert");
    },
  },
  mounted() {
    this.titulo = this.titulomodalAlert;
    this.texto = this.textoModal;
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
#BackgroundSvgs {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

#flex {
  margin-left: -90%;
}

.container-modal {
  height: 240px;
  width: 880px;
  left: 0px;
  top: 0px;
  border-radius: 10px !important;
}

.modlaAlert {
  height: 5em !important;
}
</style>
