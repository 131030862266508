<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-autocomplete
            item-text="descripcion"
            item-value="valor"
            :items="tipos"
            v-model="tipo"
            label="Tipo de documento"
            :loading="loadingTipos"
            name="tipoDocumento"
            :rules="[(v) => !!v || 'Debe seleccionar un tipo.']"
          ></v-autocomplete>

          <v-text-field
            v-model="codigo"
            :rules="[(v) => !!v || 'El campo código es requerido.']"
            name="codigoDocumento"
            label="Código de documento"
            placeholder="Escriba nombre del documento"
            id="documento"
          >
          </v-text-field>
          <v-text-field
            v-model="documento_descripcion"
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
            name="descripcionDocumento"
            label="Descripción"
            placeholder="Escriba nombre del documento"
            id="documento"
          >
          </v-text-field>
          <v-checkbox
            v-model="genera_compania"
            label="Genera Compañia"
            hide-details
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "DocsEmisionModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario",
      codigo: "",
      tipo: null,
      tipos: [],
      genera_compania: false,
      loadingTipos: false,
      loadingForm: false,
      documento_descripcion: "",
    };
  },
  methods: {
    ...mapActions("NewProduct", [
      "getParametrizacionByDominio",
      "CrearDocumentEmision",
    ]),

    setup() {
      this.tipos = [];
      this.loadingTipos = true;
      const dominio = "documentos-venta";
      this.getParametrizacionByDominio(dominio)
        .then((response) => {
          this.tipos = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingTipos = false;
        });
    },

    setter() {
      this.codigo = this.formData.codigo;
      this.tipo = this.formData.tipo;
      this.genera_compania = this.formData.genera_compania;
      this.documento_descripcion = this.formData.documento_descripcion;
    },

    send() {
      this.loadingForm = true;
      const formData = {
        documento_descripcion: this.documento_descripcion,
        tipo: this.tipo,
        codigo: this.codigo,
        genera_compania: this.genera_compania,
      };
      this.CrearDocumentEmision(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.$emit("submit");
        })
        .catch((error) => {
          console.log(error);
          this.snackbar(
            error.result?.lista_mensaje?.[0]?.mensaje || error.info
          );
          this.$emit("close");
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
