<template>
  <div class="father-div">
    <v-container wrap>
      <v-expansion-panels accordion focusable flat multiple v-model="panel">
        <v-expansion-panel
          v-for="(item, i) in categories"
          :key="i"
          :id="`section-${item.ref}`"
        >
          <v-expansion-panel-header
            color="primary lighten-5 primary--text text--darken-1"
            class="font-weight-bold subtitle-1"
            v-show="!('show' in item) || item.show"
            >{{ item.text }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <!-- COMPANIA -->
            <v-container wrap class="pa-2" v-if="item.id == 11">
              <div class="mt-3">
                <v-row align="center">
                  <v-col
                    cols="6"
                    sm="4"
                    md="3"
                    v-for="(company, index) of companies"
                    :key="index"
                  >
                    <div
                      :class="{
                        'picture-box d-flex align-center justify-center': true,
                        selected: company.id === form.id_compania,
                      }"
                      @click="handlerSelectCompany(company)"
                      :title="company.descripcion"
                    >
                      <span>
                        <v-img
                          :src="company.logo"
                          max-width="100"
                          :alt="company.descripcion"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-6"></v-divider>
              </div>
            </v-container>

            <!-- CODIGOS -->
            <v-container wrap class="pa-2" v-if="item.id == 111">
              <h4 class="subtitle-1 mb-3 font-weight-medium">
                Códigos del Producto
              </h4>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Código Interno"
                    outlined
                    v-model="form.codigos_producto.interno"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Código Externo"
                    outlined
                    v-model="form.codigos_producto.externo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <!-- DATOS ADICIONALES -->
            <v-container wrap class="pa-2" v-else-if="item.id == 112">
              <!-- INFO HDI -->
              <v-row
                class="mt-3"
                multi-line
                :no-gutters="mobile"
                v-if="form?.compania?.codigo == staticCompanies.hdi.code"
              >
                <!-- INTERMEDIARIO 1 -->
                <v-col cols="12" md="6">
                  <div class="d-flex align-center">
                    <h5>Intermediario 1</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.intermediario_1
                          .tipo == 'financiera' ||
                        form.informacion_adicional.tarifa.intermediario_1
                          .tipo == 'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(1)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>

                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.intermediario_1.tipo
                    "
                    outlined
                    label="Opciones"
                    :items="opciones"
                    dense
                    item-text="nombre"
                    item-value="codigo"
                    @change="(tipo = 1), optModal($event)"
                    :messages="hint1"
                  ></v-select>
                </v-col>

                <!-- RUT HOMOLOGACION -->
                <v-col cols="12" md="6">
                  <div class="d-flex align-center">
                    <h5>Rut Homologación</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.taxid_homologacion
                          .tipo == 'financiera' ||
                        form.informacion_adicional.tarifa.taxid_homologacion
                          .tipo == 'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(2)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.taxid_homologacion.tipo
                    "
                    outlined
                    dense
                    label="Opciones"
                    :items="opciones"
                    item-text="nombre"
                    item-value="codigo"
                    :messages="hint2"
                    @change="(tipo = 2), optModal($event)"
                  ></v-select>
                </v-col>

                <!-- INTERMEDIARIO 2 -->
                <v-col cols="12" md="6">
                  <div class="d-flex align-end">
                    <v-checkbox
                      v-model="
                        form.informacion_adicional.tarifa.intermediario_2
                          .habilitado
                      "
                      label=""
                      dense
                      hide-details
                    ></v-checkbox>
                    <h5>Intermediario 2</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.intermediario_2
                          .tipo == 'financiera' ||
                        form.informacion_adicional.tarifa.intermediario_2
                          .tipo == 'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(3)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.intermediario_2.tipo
                    "
                    :disabled="
                      !form.informacion_adicional.tarifa.intermediario_2
                        .habilitado
                    "
                    outlined
                    dense
                    label="Opciones"
                    :items="opciones"
                    item-text="nombre"
                    item-value="codigo"
                    :messages="hint3"
                    @change="(tipo = 3), optModal($event)"
                  ></v-select>
                </v-col>

                <!-- RUT CORREDOR -->
                <v-col cols="12" md="6" class="pt-6">
                  <div class="d-flex align-center">
                    <h5>Rut Corredor</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.taxid_corredor.tipo ==
                          'financiera' ||
                        form.informacion_adicional.tarifa.taxid_corredor.tipo ==
                          'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(4)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.taxid_corredor.tipo
                    "
                    outlined
                    dense
                    label="Opciones"
                    :items="opciones"
                    item-text="nombre"
                    item-value="codigo"
                    :messages="hint4"
                    @change="(tipo = 4), optModal($event)"
                  ></v-select>
                </v-col>

                <!-- INTERMEDIARIO 3 -->
                <v-col cols="12" md="6">
                  <div class="d-flex align-end">
                    <v-checkbox
                      v-model="
                        form.informacion_adicional.tarifa.intermediario_3
                          .habilitado
                      "
                      label=""
                      dense
                      hide-details
                    ></v-checkbox>
                    <h5>Intermediario 3</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.intermediario_3
                          .tipo == 'financiera' ||
                        form.informacion_adicional.tarifa.intermediario_3
                          .tipo == 'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(5)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>

                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.intermediario_3.tipo
                    "
                    :disabled="
                      !form.informacion_adicional.tarifa.intermediario_3
                        .habilitado
                    "
                    outlined
                    dense
                    label="Opciones"
                    :items="opciones"
                    item-text="nombre"
                    item-value="codigo"
                    :messages="hint5"
                    @change="(tipo = 5), optModal($event)"
                  ></v-select>
                </v-col>

                <!-- ID CONVENIO -->
                <v-col cols="12" md="6" class="pt-6">
                  <div class="d-flex align-center">
                    <h5>ID Convenio</h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        form.informacion_adicional.tarifa.taxid_convenio.tipo ==
                          'financiera' ||
                        form.informacion_adicional.tarifa.taxid_convenio.tipo ==
                          'otro'
                      "
                      color="primary"
                      text
                      fab
                      x-small
                      @click="editOptions(6)"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </div>

                  <v-select
                    v-model="
                      form.informacion_adicional.tarifa.taxid_convenio.tipo
                    "
                    outlined
                    label="Opciones"
                    :items="opciones"
                    item-text="nombre"
                    item-value="codigo"
                    :messages="hint6"
                    dense
                    @change="(tipo = 6), optModal($event)"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- INFO ZURICH -->
              <v-row
                class="mt-3"
                multi-line
                :no-gutters="mobile"
                v-if="form?.compania?.codigo == staticCompanies.zurich2.code"
              >
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="form.informacion_adicional.username"
                    label="Usuario"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="form.informacion_adicional.password"
                    :type="text_password"
                    :append-icon="
                      text_password == 'password' ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append="
                      text_password == 'password'
                        ? (text_password = 'text')
                        : (text_password = 'password')
                    "
                    label="Contraseña"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <!-- CORREDORES DE SEGUROS -->
            <v-container v-else-if="item.id == 110">
              <v-fade-transition mode="out-in">
                <v-sheet
                  v-if="!insuranceBroker.selected"
                  class="d-flex align-center justify-center selectable elevation-2"
                  title="Corredor de seguro"
                  height="205"
                  @click="handlerOpenBrokersList"
                >
                  <p class="subtitle-1 font-weight-medium primary--text">
                    Haz clic aquí para seleccionar un corredor de seguro
                  </p>
                </v-sheet>

                <v-card v-else>
                  <v-list two-line>
                    <v-subheader
                      >Corredor de Seguro
                      <v-spacer></v-spacer>
                      <v-btn color="error" icon @click="resetInsuranceBroker"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <p :class="[mobile ? 'subtitle-1' : 'text-h5']">
                          {{
                            insuranceBroker.selected.corredor_descripcion || ""
                          }}
                        </p>
                        <v-list-item-subtitle class="subtitle-2"
                          >{{ insuranceBroker.selected.tipo_documento || "" }}
                          {{
                            insuranceBroker.selected.numero_documento || ""
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :class="{ 'flex-column': mobile }">
                      <v-list-item-content>
                        <v-list-item-title>Póliza</v-list-item-title>
                        <p class="caption">
                          <b>Responsabilidad:</b> <br />
                          <span>{{
                            insuranceBroker.selected.poliza_responsabilidad ||
                            ""
                          }}</span>
                        </p>
                      </v-list-item-content>
                      <v-list-item-content>
                        <p class="caption">
                          <b>Garantía:</b> <br />
                          <span>{{
                            insuranceBroker.selected.poliza_garantia || ""
                          }}</span>
                        </p>
                      </v-list-item-content>

                      <!-- Descomentar si amerita imagen -->
                      <!-- <v-img
                      :src="GetLogo"
                      :max-width="mobile ? 190 : 220"
                      max-height="95"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img> -->
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-divider vertical class="mx-2"></v-divider>
                    <v-text-field
                      dense
                      name="corredor_porcentaje"
                      label="Porcentaje"
                      style="max-width: 120px"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                      hide-details
                      v-model="form.porcentaje_corredor"
                      outlined
                      type="number"
                      suffix="%"
                    ></v-text-field>
                  </v-card-actions>
                </v-card>
              </v-fade-transition>
              <p class="caption mt-3">
                Info: El corredor de seguro seleccionado se convierte
                automaticamente en el responsable del producto.
              </p>
            </v-container>

            <!-- RAMO -->
            <v-container wrap class="pa-2" v-else-if="item.id == 18">
              <v-radio-group
                v-model="form.ramo"
                row
                @change="
                  getAllSegment($event, true),
                    handlerGetProductUse($event, true)
                "
              >
                <div
                  class="d-flex align-center pr-6 py-1"
                  v-for="(item, i) in Ramos"
                  :key="i"
                  :style="{ width: mobile ? '100%' : '50%' }"
                >
                  <v-radio
                    :label="item.ramo_descripcion"
                    :value="item._id"
                    :disabled="item.eliminado"
                  >
                  </v-radio>
                  <v-spacer></v-spacer>
                  <v-btn
                    x-small
                    color="error"
                    fab
                    text
                    v-if="!item.eliminado"
                    @click="
                      openDelete('Ramo', 1, item._id, item.ramo_descripcion),
                        (deleteLevel = 1)
                    "
                    ><v-icon> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-radio-group>

              <div class="text-center">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="info"
                      text
                      @click="openModal('Ramo')"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon left>mdi-plus-circle-outline</v-icon>Crear</v-btn
                    >
                  </template>
                  <span>Agregar un nuevo Ramo</span>
                </v-tooltip>
              </div>
            </v-container>

            <!-- SEGMENTO -->
            <v-container wrap class="pa-2" v-if="item.id == 16">
              <div class="d-flex align-center">
                <v-fade-transition mode="out-in">
                  <v-radio-group
                    v-model="form.segmento"
                    v-if="!loading.segment && getSegments.length > 0"
                    style="width: 100%"
                    @change="getTipoproduct($event, true)"
                  >
                    <v-row no-gutters>
                      <v-col
                        v-for="(item, i) in getSegments"
                        :key="i"
                        class="pr-6"
                        cols="12"
                        md="6"
                      >
                        <div class="d-flex align-center">
                          <v-radio
                            :disabled="item.eliminado"
                            :label="item.segmento_descripcion"
                            :value="item._id"
                            hide-details
                            class="mt-3"
                          ></v-radio>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="!item.eliminado"
                            color="error"
                            text
                            fab
                            x-small
                            @click="
                              openDelete(
                                'Segmento',
                                30,
                                item._id,
                                item.segmento_descripcion
                              ),
                                (deleteLevel = 2)
                            "
                          >
                            <v-icon> mdi-close </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                  <p class="caption" v-else-if="!loading.segment">
                    <span
                      v-if="form.ramo === ''"
                      class="primary--text text--darken-4"
                      ><v-icon>mdi-information-outline</v-icon> Por favor
                      seleccione un ramo</span
                    >
                    <span v-else class="error--text"
                      ><v-icon color="error">mdi-alert-circle-outline</v-icon>
                      No se han encontrado Segmentos para el Ramo
                      seleccionado.</span
                    >
                  </p>
                  <div v-else class="pt-8 px-6">
                    <v-progress-circular
                      indeterminate
                      size="64"
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-fade-transition>
              </div>

              <v-row>
                <v-col class="text-center" cols="12" v-if="form.ramo !== ''">
                  <v-menu
                    v-model="modal.show.segment"
                    :close-on-content-click="false"
                    :nudge-width="200"
                  >
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            color="info"
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            text
                          >
                            <v-icon left>mdi-plus-circle-outline</v-icon>
                            Crear
                          </v-btn>
                        </template>
                        <span>Agregar un nuevo Segmento</span>
                      </v-tooltip>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-text-field
                            v-model="modal.form.segment"
                            dense
                            name="name"
                            label="Segmento"
                            id="id"
                            outlined
                            hint="Descripción del nuevo segmento"
                            persistent-hint
                          ></v-text-field>
                        </v-list-item>
                      </v-list>

                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="saveSegment"
                          :loading="modal.loading.segment"
                        >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>

            <!-- MATERIA ASEGURADA -->
            <v-container wrap class="pa-2" v-else-if="item.id == 15">
              <v-row>
                <v-col cols="12" md="12" align-self="center">
                  <v-fade-transition mode="out-in">
                    <v-radio-group
                      v-model="form.materiaAsegurada"
                      v-if="!loading.insuredMaterial && Materias.length > 0"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          xl="4"
                          v-for="(item, i) in Materias"
                          :key="i"
                          class="d-flex align-center"
                        >
                          <v-radio
                            :disabled="item.eliminado"
                            v-if="banderaMateria == true"
                            :label="item.materia_descripcion"
                            :value="item.materia"
                            class="my-0"
                            hide-details
                          ></v-radio>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            fab
                            x-small
                            v-if="!item.eliminado"
                            @click="
                              openDelete(
                                'Materia asegurada',
                                2,
                                item.materia,
                                item.materia_descripcion
                              ),
                                (deleteLevel = 4)
                            "
                          >
                            <v-icon small> mdi-close </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                    <p class="caption" v-else-if="!loading.insuredMaterial">
                      <span
                        v-if="form.producto === ''"
                        class="primary--text text--darken-4"
                        ><v-icon>mdi-information-outline</v-icon> Por favor
                        seleccione un Tipo de Producto</span
                      >
                      <span v-else class="error--text"
                        ><v-icon color="error">mdi-alert-circle-outline</v-icon>
                        No se han encontrado materia asegurada para el Tipo de
                        Producto seleccionado.</span
                      >
                    </p>
                    <div v-else class="pt-8 px-6">
                      <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-fade-transition>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center pa-0"
                  v-if="form.producto !== ''"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        text
                        @click="valueMateria()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="form.producto === ''"
                        ><v-icon left>mdi-plus-circle-outline</v-icon>
                        Crear</v-btn
                      >
                    </template>
                    <span>Agregar una nueva Materia Asegurada</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>

            <!-- TIPO DE PRODUCTO -->
            <v-container wrap class="pa-2" v-else-if="item.id == 12">
              <v-row>
                <v-col cols="12" md="12" align-self="center">
                  <v-fade-transition mode="out-in">
                    <v-radio-group
                      v-model="form.producto"
                      @change="getMaterias($event, true)"
                      row
                      v-if="!loading.productType && Tproductos.length > 0"
                    >
                      <div
                        v-for="(item, i) in Tproductos"
                        :key="i"
                        class="custom-radio d-flex"
                      >
                        <v-radio
                          :disabled="item.eliminado"
                          v-if="banderaTipoProduct == true"
                          :label="item.tipo_producto_descripcion"
                          :value="item.tipo_producto"
                          @change="
                            guardarNemotecnico(
                              item.tipo_producto_descripcion,
                              item._id
                            )
                          "
                        ></v-radio>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="!item.eliminado"
                          color="error"
                          class="mr-2"
                          x-small
                          fab
                          text
                          @click="
                            openDelete(
                              'Tipo Producto',
                              3,
                              item.tipo_producto,
                              item.tipo_producto_descripcion
                            ),
                              (deleteLevel = 3)
                          "
                        >
                          <v-icon> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </v-radio-group>
                    <p class="caption" v-else-if="!loading.productType">
                      <span
                        v-if="form.segmento === ''"
                        class="primary--text text--darken-4"
                        ><v-icon>mdi-information-outline</v-icon> Por favor
                        seleccione un Segmento</span
                      >
                      <span v-else class="error--text"
                        ><v-icon color="error">mdi-alert-circle-outline</v-icon>
                        No se han encontrado tipos de productos para el Segmento
                        seleccionado.</span
                      >
                    </p>
                    <div v-else class="pt-8 px-6">
                      <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-fade-transition>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center pa-0"
                  v-if="form.segmento !== ''"
                >
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="state_modal_tipo_product = true"
                      >
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        Crear
                      </v-btn>
                    </template>
                    <span>Agregar un nuevo tipo de producto</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>

            <!-- MONEDA DEDUCIBLE -->
            <v-container wrap class="pa-2 mt-6" v-else-if="item.id == 113">
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="form.moneda_deducible.moneda_id"
                    :search-input.sync="
                      form.moneda_deducible.moneda_descripcion
                    "
                    :items="Monedas"
                    item-text="moneda_descripcion"
                    item-value="moneda"
                    item-disabled="eliminado"
                    label="Moneda"
                    outlined
                    append-outer-icon="mdi-plus-circle-outline"
                    @click:append-outer="state_modal_Moneda = true"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- TIPO DE MONEDA -->
            <v-container wrap class="pa-2 mt-6" v-else-if="item.id == 13">
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="form.moneda.pais"
                    :items="Pais"
                    item-text="pais_descripcion"
                    item-value="pais"
                    item-disabled="eliminado"
                    label="Pais"
                    outlined
                    append-outer-icon="mdi-plus-circle-outline"
                    @click:append-outer="openModalPais('Pais')"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="form.moneda.valor"
                    :items="Monedas"
                    item-text="moneda_descripcion"
                    item-value="moneda"
                    item-disabled="eliminado"
                    label="Moneda"
                    outlined
                    append-outer-icon="mdi-plus-circle-outline"
                    @click:append-outer="state_modal_Moneda = true"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- USO DEL PRODUCTO -->
            <v-container wrap class="pa-2" v-else-if="item.id == 17">
              <v-fade-transition mode="out-in">
                <v-row
                  v-if="!loading.productUse && getProductUse.length > 0"
                  dense
                  class="mt-6"
                >
                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    v-for="(u, index) in getProductUse"
                    :key="index"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="form.uso_producto"
                        :label="u.uso_producto_descripcion"
                        :value="u.uso_producto"
                        :disabled="u.eliminado"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="!u.eliminado"
                        color="error"
                        text
                        fab
                        x-small
                        @click="
                          openDelete(
                            'Uso del Producto',
                            31,
                            u.uso_producto || u._id,
                            u.uso_producto_descripcion
                          ),
                            (deleteLevel = 5)
                        "
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <p class="caption" v-else-if="!loading.productUse">
                  <span
                    v-if="form.ramo === ''"
                    class="primary--text text--darken-4"
                    ><v-icon>mdi-information-outline</v-icon> Por favor
                    seleccione un ramo</span
                  >
                  <span v-else class="error--text"
                    ><v-icon color="error">mdi-alert-circle-outline</v-icon> No
                    se han encontrado usos para el producto del Ramo
                    seleccionado.</span
                  >
                </p>
                <div v-else class="pt-8 px-6">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-fade-transition>

              <v-col cols="12" class="text-center">
                <!-- Formulario del uso del producto -->
                <v-menu
                  v-model="modal.show.productUse"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="info"
                          dark
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          text
                        >
                          <v-icon left>mdi-plus-circle-outline</v-icon>
                          Crear
                        </v-btn>
                      </template>
                      <span>Agregar un nuevo uso para el producto</span>
                    </v-tooltip>
                  </template>

                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-row no-gutters>
                          <v-col cols="6" md="8">
                            <v-text-field
                              v-model="modal.form.productUse"
                              dense
                              label="Uso del Producto"
                              outlined
                              hint="Descripción del nuevo uso para el Producto"
                              persistent-hint
                              class="mr-1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="4">
                            <v-text-field
                              v-model="modal.form.productUseCode"
                              dense
                              label="Código"
                              outlined
                              hint="Código de referencia"
                              class="ml-1"
                              persistent-hint
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>

                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="saveProductUse"
                        :loading="modal.loading.productUse"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-container>

            <!-- NOMBRE COMERCIAL -->
            <div v-else-if="item.id == 14" class="d-flex mt-6">
              <v-text-field
                v-model="form.nombreComercial"
                name="nombre-comercial"
                :label="nombrecomercial.label"
                :placeholder="nombrecomercial.text"
                id="id_nombre_comercial"
                outlined
              ></v-text-field>
            </div>

            <!-- ESTADO DEL PRODUCTO -->
            <v-container v-else-if="item.id == 19 && editMode" class="px-0">
              <v-divider class="mb-6"></v-divider>

              <h4 class="title mb-2 text-right">Estado del producto:</h4>

              <v-row justify="end">
                <v-col cols="12" md="4">
                  <v-select
                    dense
                    :loading="loading.status"
                    outlined
                    :items="statusList"
                    item-disabled="eliminado"
                    v-model="form.status"
                    item-value="estado"
                    item-text="estado_descripcion"
                    placeholder="Seleccione un estado"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <modal-ramo
      v-model="state_modal_ramo"
      @close="state_modal_ramo = false"
      @submit="getRamosList(), (state_modal_ramo = false)"
    />
    <modal-materia
      v-model="state_modal_materia"
      @close="state_modal_materia = false"
      @submit="
        getMaterias(form?.producto ?? null), (state_modal_materia = false)
      "
      :tipoProductoId="form.producto"
    />
    <ModalAlert
      v-if="state_modal_alert"
      @cerrarmodalAlert="cerrarmodalAlert"
      :titulomodalAlert="titulomodalAlert"
      :textoModal="textoModal"
    />
    <form-tipo-producto
      v-model="state_modal_tipo_product"
      @close="state_modal_tipo_product = false"
      @submit="
        (state_modal_tipo_product = false), getTipoproduct(form.segmento)
      "
      :segmentId="form.segmento"
      :ramoId="form.ramo"
    />
    <modal-pais
      v-model="state_modal_pais"
      @close="state_modal_pais = false"
      @submit="getPais(), (state_modal_pais = false)"
    />
    <modal-moneda
      v-model="state_modal_Moneda"
      @close="state_modal_Moneda = false"
      @submit="getMonedas(), (state_modal_Moneda = false)"
    />
    <ModalDelet
      v-if="state_modal_delete"
      @cerrarmodalDelete="cerrarmodalDelete"
      :titulomodal="titulomodal"
      :step_id="step_id"
      :ids="ids"
      :dataDescription="dataDescription"
    />
    <ModalOpt
      v-if="state_modal_opt"
      @cerrarmodalOpt="cerrarmodalOpt"
      :valor="valor"
      :editOpt="editOpt"
      :normalOpt="normalOpt"
    />

    <!-- MODAL DEL LISTADO DE CORREDOR DE SEGURO -->
    <BaseModal
      v-model="insuranceBroker.show"
      :loading="insuranceBroker.loading"
      @close="insuranceBroker.show = false"
    >
      <h4 class="mt-4 px-3">
        Selecciona un corredor de seguro para este Producto
      </h4>
      <div class="my-3 ml-3 mr-6 d-flex">
        <v-text-field
          dense
          v-model="insuranceBroker.filter"
          outlined
          clearable
          label="Buscar corredor de seguro"
          type="text"
          hide-details
          @click:clear="(insuranceBroker.filter = ''), setBrokersListData()"
        ></v-text-field>
        <v-divider vertical class="mx-3"></v-divider>
        <v-btn color="primary" @click="setBrokersListData()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-divider class="mt-6"></v-divider>
      <InsuranceBrokerList
        :items="brokersList"
        @select-item="handlerSelectBroker"
      />
      <v-card-text>
        <v-pagination
          :value="insuranceBroker.current"
          circle
          :length="insuranceBroker.pages"
          :total-visible="7"
          @input="(insuranceBroker.current = $event), setBrokersListData()"
        ></v-pagination>
      </v-card-text>
    </BaseModal>

    <!-- NOTIFICACION O TOASTER -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="timeout"
      :right="x === 'right'"
      :top="y === 'top'"
      :color="color_message"
    >
      <v-icon class="global-icon7 mr-5">{{ icon }}</v-icon>
      {{ text_Menssage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ModalAlert from "@/components/Modal/ModalAlert/Modal-alert.vue";
import ModalDelet from "@/components/Modal/ModalAlert/Modal-deletConf.vue";

import ModalRamo from "@/components/Modal/ModalProduct/Modal-Ramo.vue";
import ModalMateria from "@/components/Modal/ModalProduct/Modal-Materia.vue";
import FormTipoProducto from "@/components/Forms/cruds/tipoProducto.vue";
import ModalPais from "@/components/Modal/ModalProduct/Modal-Pais.vue";
import ModalMoneda from "@/components/Modal/ModalProduct/Modal-Moneda.vue";
import ModalOpt from "@/components/Modal/ModalProduct/Modal-Options.vue";
import BaseModal from "@/components/Modal/base.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

import InsuranceBrokerList from "@/components/Lists/InsuranceBrokerList.vue";

export default {
  props: {
    referencia: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      description: "Listado de Categorias",
      default: () => [],
    },
    search: {
      type: [String, Number],
      require: true,
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Variable para definir actualizar el formulario",
    },
    mobile: {
      type: Boolean,
      default: false,
      description: "Variable de interfaz modo Telefono&Tablet",
    },
    staticCompanies: {
      type: Object,
      default: () => {},
      description: "Informacion estatica de las companias en el front",
    },
  },
  data() {
    return {
      form: {
        ramo: "",
        producto: "",
        codigos_producto: {
          interno: "",
          externo: "",
        },
        moneda: {
          valor: "",
          pais: "",
        },
        moneda_deducible: {
          moneda_id: "",
          moneda_descripcion: "",
        },
        materiaAsegurada: "",
        nombreComercial: "",
        segmento: "",
        editar: false,
        uso_producto: [],
        id_compania: "",
        compania: null,
        status: "",
        corredor: "",
        porcentaje_corredor: "",
        informacion_adicional: {
          username: "",
          password: "",
          tarifa: {
            intermediario_1: {
              habilitado: true,
              tipo: "",
              valor: "",
            },
            intermediario_2: {
              habilitado: false,
              tipo: "",
              valor: "",
            },
            intermediario_3: {
              habilitado: false,
              tipo: "",
              valor: "",
            },
            taxid_homologacion: {
              habilitado: true,
              tipo: "",
              valor: "",
            },
            taxid_corredor: {
              habilitado: true,
              tipo: "",
              valor: "",
            },
            taxid_convenio: {
              habilitado: true,
              tipo: "",
              valor: "",
            },
          },
        },
      },
      opciones: [],

      Ramos: [],
      ramoId: "",
      searchRamo: "",

      loading: {
        productType: false,
        productUse: false,
        insuredMaterial: false,
        segment: false,
        status: false,
      },

      Tproductos: [],
      Pais: [],
      Monedas: [],
      Materias: [],
      exclusividad: "",
      companias: "",
      insuranceBroker: {
        show: false,
        selected: null,
        loading: false,
        current: 1,
        pages: 1,
        limit: 5,
        filter: "",
      },

      banderaMateria: false,
      banderaTipoProduct: false,

      state_modal_ramo: false,
      state_modal_materia: false,
      state_modal_alert: false,
      state_modal_tipo_product: false,
      state_modal_pais: false,
      state_modal_Moneda: false,
      state_modal_delete: false,
      state_modal_opt: false,

      titulomodal: "ejemplo",
      titulomodalAlert: "ejemplo",
      textoModal: "ejemplo",

      panel: [],

      nombrecomercial: {
        label: "Nombre Comercial",
        text: "Escribe el nombre comercial del producto",
        disabled: false,
      },

      multiLine: true,
      snackbar: false,
      text_Menssage: "",
      y: "top",
      x: "right",
      timeout: 2000,
      color_message: "",
      icon: "",
      // Nuevas variables
      ids: "",
      deleteLevel: 0,
      valor: "",
      editOpt: "",
      normalOpt: "",
      hint1: "",
      hint2: "",
      hint3: "",
      hint4: "",
      hint5: "",
      hint6: "",
      modal: {
        form: {
          segment: "",
          productUse: "",
          productUseCode: "",
        },
        loading: {
          segment: false,
          productUse: false,
        },
        show: {
          segment: false,
          productUse: false,
        },
      },
      subOptions: "",
      tipo: 0,
      concesionarios: [],
      financieras: [],
      text_password: "password",
    };
  },
  async created() {
    if (this.editMode) {
      this.form.status = this.prod.estado;
      const definicion_basica = this.prod.definicion_basica;
      await this.setup(definicion_basica);

      this.$store.commit("ramosId", definicion_basica.ramo);
      this.get();
    } else {
      this.get();
    }
  },
  mounted() {
    let counter = 0;
    this.categories.forEach(() => {
      this.panel.push(counter);
      counter++;
    });
  },
  watch: {
    referencia() {
      let element = this.$refs[this.referencia];
      let e = element[this.referencia - 1];
      let posicion = e.getBoundingClientRect();
      this.$emit("scroll", posicion.top);
    },
  },

  computed: {
    ...mapGetters("NewProduct", [
      "getSegments",
      "getProductUse",
      "companies",
      "statusList",
      "brokersList",
    ]),
    prod() {
      return JSON.parse(localStorage.getItem("produc"));
    },
  },

  components: {
    ModalRamo,
    ModalMateria,
    ModalAlert,
    FormTipoProducto,
    ModalPais,
    ModalMoneda,
    ModalDelet,
    ModalOpt,
    BaseModal,

    InsuranceBrokerList,
  },
  methods: {
    ...mapActions("NewProduct", [
      "CreateSegment",
      "CreateProductUse",
      "getRamos",
      "getTipoProductoBySegment",
      "getPaises",
      "getMateria",
      "getMoneda",
      "getSegmentByRamo",
      "GetProductUseByRamo",
      "GetCompanies",
      "getOpcionesAdicionales",
      "getFinancieras",

      "REQUEST_PRODUCT_STATUS",
      "REQUEST_INSURANCE_BROKER",
      "REQUEST_INSURANCE_BROKER_BY_ID",
    ]),
    ...mapMutations("NewProduct", ["resetSegments", "setCompanyById"]),

    setup(data) {
      return new Promise((resolve) => {
        console.log(data);
        this.form.ramo = data.ramo;
        this.$store.commit("ramosId", data.ramo);
        this.form.materiaAsegurada = data.materia_asegurada;
        this.form.segmento = data.segmento || "";
        this.form.producto = data.tipo_producto;
        this.form.moneda.valor = data.tipo_moneda.moneda;
        this.form.moneda.pais = data.tipo_moneda.pais;
        this.form.moneda_deducible.moneda_id =
          data.moneda_deducible?.moneda_id || "";
        this.form.moneda_deducible.moneda_descripcion =
          data.moneda_deducible?.moneda_descripcion || "";
        this.form.nombreComercial = data.nombre_comercial;
        this.form.uso_producto = data.uso_producto || [];
        // Establecer compania seleccionada
        const { id_compania, compania } = data;
        this.handlerSelectCompany({ id: id_compania, ...compania });
        // Establecer corredor de seguros
        this.form.corredor = data.corredor || "";
        this.form.porcentaje_corredor = data.porcentaje_corredor || 0;
        // ---
        this.form.editar = true;
        this.form.codigos_producto.interno = data.codigos_producto
          ? data.codigos_producto.interno || ""
          : "";
        this.form.codigos_producto.externo = data.codigos_producto
          ? data.codigos_producto.externo || ""
          : "";
        if (data?.informacion_adicional) {
          // Establecer informacion adicional de usuario y contrasena.
          this.form.informacion_adicional.username =
            data?.informacion_adicional.username || "";
          this.form.informacion_adicional.password =
            data?.informacion_adicional.password || "";
          // ---
          this.form.informacion_adicional = data?.informacion_adicional || {};
          /*this.form.informacion_adicional.tarifa.intermediario_3 = data?.informacion_adicional?.tarifa?.intermediario_3 || {}; //quitar
          this.form.informacion_adicional.tarifa.taxid_convenio = data?.informacion_adicional?.tarifa?.taxid_convenio || {}; //quitar
           this.form.informacion_adicional.tarifa.intermediario_2.habilitado = 
            data?.informacion_adicional?.tarifa?.intermediario_2?.habilitado || false;
          this.form.informacion_adicional.tarifa.intermediario_2.tipo = 
            data?.informacion_adicional?.tarifa?.intermediario_2?.tipo || "";
          this.form.informacion_adicional.tarifa.intermediario_3.habilitado = 
            data?.informacion_adicional?.tarifa?.intermediario_3?.habilitado || false;
          this.informacion_adicional.tarifa.intermediario_3.tipo = 
            data?.informacion_adicional?.tarifa?.intermediario_3?.tipo || ""; */
          this.hint1 = data?.informacion_adicional?.tarifa?.intermediario_1
            ? /* data?.informacion_adicional?.tarifa?.intermediario_1?.tipo == "concesionario_canal" ? 
                this.setConcesionarios(data?.informacion_adicional?.tarifa?.intermediario_1?.valor, 1) :  */
              data?.informacion_adicional?.tarifa?.intermediario_1?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.intermediario_1?.valor,
                  1
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.intermediario_1?.valor
                )
            : "";
          this.hint2 = data?.informacion_adicional?.tarifa?.taxid_homologacion
            ? /* data?.informacion_adicional?.tarifa?.taxid_homologacion?.tipo == "concesionario_canal" ? 
                this.setConcesionarios(data?.informacion_adicional?.tarifa?.taxid_homologacion?.valor, 2) : */
              data?.informacion_adicional?.tarifa?.taxid_homologacion?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.taxid_homologacion
                    ?.valor,
                  2
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.taxid_homologacion?.valor
                )
            : "";
          this.hint3 = data?.informacion_adicional?.tarifa?.intermediario_2
            ? /* data?.informacion_adicional?.tarifa?.intermediario_2?.tipo == "concesionario_canal" ? 
                this.setConcesionarios(data?.informacion_adicional?.tarifa?.intermediario_2?.valor, 3) : */
              data?.informacion_adicional?.tarifa?.intermediario_2?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.intermediario_2?.valor,
                  3
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.intermediario_2?.valor
                )
            : "";
          this.hint4 = data?.informacion_adicional?.tarifa?.taxid_corredor
            ? /* data?.informacion_adicional?.tarifa?.taxid_corredor?.tipo == "concesionario_canal" ? 
                this.setConcesionarios(data?.informacion_adicional?.tarifa?.taxid_corredor?.valor, 4) : */
              data?.informacion_adicional?.tarifa?.taxid_corredor?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.taxid_corredor?.valor,
                  4
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.taxid_corredor?.valor
                )
            : "";
          this.hint5 = data?.informacion_adicional?.tarifa?.intermediario_3
            ? /* data?.informacion_adicional?.tarifa?.intermediario_3?.tipo == "concesionario_canal" ? 
                  this.setConcesionarios(data?.informacion_adicional?.tarifa?.intermediario_3?.valor, 5) : */
              data?.informacion_adicional?.tarifa?.intermediario_3?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.intermediario_3?.valor,
                  5
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.intermediario_3?.valor
                )
            : "";
          this.hint6 = data?.informacion_adicional?.tarifa?.taxid_convenio
            ? /* data?.informacion_adicional?.tarifa?.taxid_convenio?.tipo == "concesionario_canal" ? 
                  this.setConcesionarios(data?.informacion_adicional?.tarifa?.taxid_convenio?.valor, 6) : */
              data?.informacion_adicional?.tarifa?.taxid_convenio?.tipo ==
              "financiera"
              ? this.setFinancieras(
                  data?.informacion_adicional?.tarifa?.taxid_convenio?.valor,
                  6
                )
              : this.sliceOtro(
                  data?.informacion_adicional?.tarifa?.taxid_convenio?.valor
                )
            : "";
        }
        resolve();
      });
    },

    async get() {
      this.$store.commit("statePiner", true);
      this.setSelectedBrokerData(this.form.corredor);
      this.getRamosList();
      this.getPais();
      this.getMonedas();
      this.getProductStatusList();
      this.GetCompanies().then(() => {
        if (this.form.id_compania && this.form.id_compania !== "") {
          this.setCompanyById(this.form.id_compania);
        }
      });
      await this.handlerGetObjectsByRamo(
        this.form.ramo !== "" ? this.form.ramo : null
      );
      this.getOpcionesAdicionales().then((v) => {
        this.opciones = v;
      });
      this.$store.commit("statePiner", false);
    },

    /* setConcesionarios(val, tipo){
      this.getConcesionarios().then((v) => {
        const concesionarios = v;
        this.findConcesionario(val, concesionarios, tipo)
      })
    },
    
    findConcesionario(val, conces, tipo){
      const concesionario = conces.find(e => e._id === val).canal_descripcion
      switch (tipo) {
        case 1:
          this.hint1 = concesionario
          break;

        case 2:
          this.hint2 = concesionario
          break;

        case 3:
          this.hint3 = concesionario
          break;

        case 4:
          this.hint4 = concesionario
          break;
          
        case 5:
          this.hint5 = concesionario
          break;

        case 6:
          this.hint6 = concesionario
          break;
      }
    }, */

    setFinancieras(val, tipo) {
      this.getFinancieras().then((v) => {
        const financieras = v;
        this.findFinanciera(val, financieras, tipo);
      });
    },

    findFinanciera(val, finans, tipo) {
      const financiera = finans.find(
        (e) => e.financiera === val
      ).financiera_descripcion;
      switch (tipo) {
        case 1:
          this.hint1 = financiera;
          break;

        case 2:
          this.hint2 = financiera;
          break;

        case 3:
          this.hint3 = financiera;
          break;

        case 4:
          this.hint4 = financiera;
          break;

        case 5:
          this.hint5 = financiera;
          break;

        case 6:
          this.hint6 = financiera;
          break;
      }
    },

    sliceOtro(v) {
      const otro = v?.length > 55 ? v?.slice(0, 55) + "..." : v;
      return otro;
    },

    cerrarmodalOpt(accion, financiera, otro) {
      if (financiera || otro) {
        if (this.tipo == 1) {
          this.hint1 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.intermediario_1.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
        } else if (this.tipo == 2) {
          this.hint2 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.taxid_homologacion.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
        } else if (this.tipo == 3) {
          this.hint3 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.intermediario_2.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
          //this.form.informacion_adicional.tarifa.intermediario_2 = this.intermediario_2
        } else if (this.tipo == 4) {
          this.hint4 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.taxid_corredor.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
        } else if (this.tipo == 5) {
          this.hint5 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.intermediario_3.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
          //this.form.informacion_adicional.tarifa.intermediario_3 = this.intermediario_3
        } else {
          this.hint6 =
            financiera?.financiera_descripcion != null
              ? financiera.financiera_descripcion
              : this.sliceOtro(otro);
          this.form.informacion_adicional.tarifa.taxid_convenio.valor =
            financiera?.financiera != null ? financiera.financiera : otro;
        }
      }
      if (accion == false) {
        this.state_modal_opt = false;
      }
    },

    cerrarmodalAlert() {
      this.state_modal_alert = false;
    },

    async cerrarmodalDelete(action, message, color, icon) {
      if (action == false) {
        this.state_modal_delete = false;
      } else {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = color;
        this.text_Menssage = message;
        this.icon = icon;
        await this.handlerResetChainValues();
      }
    },

    openModalAlert(tipo, texto) {
      this.titulomodalAlert = tipo;
      this.textoModal = texto;
      this.state_modal_alert = true;
    },

    openModal(tipo) {
      this.titulomodal = tipo;
      this.state_modal_ramo = true;
    },

    openModalPais(tipo) {
      this.titulomodal = tipo;
      this.state_modal_pais = true;
    },

    openDelete(tipo, step, id, data) {
      this.titulomodal = tipo;
      this.step_id = step;
      this.ids = id;
      this.dataDescription = data;
      this.state_modal_delete = true;
    },

    close() {
      this.checked = "";
      this.chip = false;
    },

    HandleCheckBoxChange(i) {
      if (i) {
        if (i === "cobertura") {
          this.companias = "";
          this.exclusividad = "";
          this.$emit("Filter-Cobertura", this.cobertura);
        }
        if (i === "companias") {
          this.exclusividad = "";
        }
        if (i === "deducibles") {
          this.exclusividad = "";
          this.$emit("Filter-Deducibles", this.deducibles);
        }
        if (i === "exclusividad") {
          this.$emit("Filter-exclusividad", this.exclusividad);
        }
      }
    },

    async handlerGetObjectsByRamo(ramoId = null) {
      await this.getAllSegment(ramoId);
      await this.getTipoproduct(
        this.form.segmento && this.form.segmento !== ""
          ? this.form.segmento
          : null
      );
      await this.getMaterias(
        this.form.producto && this.form.producto !== ""
          ? this.form.producto
          : null
      );
      await this.handlerGetProductUse(ramoId);
    },

    handlerGetProductUse(ramoId = null, reset = false) {
      if (reset) {
        this.form.uso_producto = [];
      }
      this.loading.productUse = true;
      return new Promise((resolve) => {
        this.GetProductUseByRamo(ramoId).finally(
          () => ((this.loading.productUse = false), resolve())
        );
      });
    },

    async saveSegment() {
      if (this.modal.form.segment !== "") {
        this.modal.loading.segment = true;
        const payload = {
          ramo: this.form.ramo,
          segmento_descripcion: this.modal.form.segment,
        };
        await this.CreateSegment(payload)
          .then((response) => {
            this.modal.form.segment = "";
            this.modal.show.segment = false;
            this.color_message = "success";
            this.text_Menssage = response.info;
            this.icon = "mdi-checkbox-marked-circle";
            this.getAllSegment(this.form.ramo);
          })
          .catch((error) => {
            this.color_message = "error";
            this.text_Menssage = error.result?.message || error.info;
            this.icon = "mdi-cloud-alert";
          })
          .finally(() => {
            this.modal.loading.segment = false;
            this.snackbar = true;
          });
      }
    },

    async saveProductUse() {
      if (
        this.modal.form.productUse !== "" &&
        this.modal.form.productUseCode !== ""
      ) {
        this.modal.loading.productUse = true;
        const payload = {
          codigo: this.modal.form.productUseCode,
          ramo: this.form.ramo,
          uso_producto_descripcion: this.modal.form.productUse,
        };
        await this.CreateProductUse(payload)
          .then((response) => {
            this.modal.form.productUse = "";
            this.modal.show.productUse = false;
            this.color_message = "success";
            this.text_Menssage = response.info;
            this.icon = "mdi-checkbox-marked-circle";
            this.handlerGetProductUse(this.form.ramo);
          })
          .catch((error) => {
            this.color_message = "error";
            this.text_Menssage = error.info;
            this.icon = "mdi-cloud-alert";
          })
          .finally(() => {
            this.modal.loading.productUse = false;
            this.snackbar = true;
          });
      }
    },

    handlerSelectCompany(item) {
      this.form.compania = item;
      this.form.id_compania = item.id;
      if (
        item?.codigo == this.staticCompanies.hdi.code ||
        item?.codigo == this.staticCompanies.zurich2.code
      ) {
        this.$emit("show-extra-form", { step: 1, section: 112, value: true });
      }
    },

    handlerResetChainValues() {
      switch (this.deleteLevel) {
        case 1:
          this.getRamosList();
          if (this.ids === this.form.ramo) {
            this.form.segmento = "";
            this.form.materiaAsegurada = "";
            this.form.producto = "";
            this.Tproductos = [];
            this.Materias = [];
            this.resetSegments();
            this.form.ramo = "";
          }
          break;
        case 2:
          this.getAllSegment(this.form.ramo);
          if (this.ids === this.form.segmento) {
            this.form.materiaAsegurada = "";
            this.form.producto = "";
            this.Tproductos = [];
            this.Materias = [];
            this.form.segmento = "";
          }
          break;
        case 3:
          this.getTipoproduct(this.form.segmento);
          if (this.ids === this.form.producto) {
            this.form.materiaAsegurada = "";
            this.Materias = [];
            this.form.producto = "";
          }
          break;
        case 4:
          this.getMaterias(this.form.producto);
          if (this.ids === this.form.materiaAsegurada) {
            this.form.materiaAsegurada = "";
          }
          break;
        case 5: {
          const selectedIndex = this.form.uso_producto.findIndex(
            (e) => e === this.ids
          );
          if (selectedIndex >= 0) {
            this.form.uso_producto.splice(selectedIndex, 1);
          }
          this.handlerGetProductUse(this.form.ramo);
          break;
        }
        default:
          break;
      }
    },

    //RAMOS
    getRamosList() {
      this.getRamos().then((response) => {
        this.Ramos = response;
      });
    },

    // SEGMENTOS
    getAllSegment(id = null, reset = false) {
      // id => Identificador del ramo
      if (id && id !== "") {
        if (reset) {
          this.form.segmento = "";
          this.form.materiaAsegurada = "";
          this.form.producto = "";
          this.Tproductos = [];
          this.Materias = [];
        }
        this.loading.segment = true;
        this.getSegmentByRamo(id).finally(() => (this.loading.segment = false));
      }
    },
    //TIPO PRODUCTO
    async getTipoproduct(id = null, reset = false) {
      // id => Identificador del Segmento
      if (!id || id === "") {
        this.banderaTipoProduct = false;
      } else {
        this.Tproductos = [];
        if (reset) {
          this.Materias = [];
          this.form.materiaAsegurada = "";
          this.form.producto = "";
        }
        this.loading.productType = true;
        await this.getTipoProductoBySegment(id)
          .then((resp) => {
            this.Tproductos = resp;
            this.banderaTipoProduct = true;
          })
          .finally(() => (this.loading.productType = false));
      }
    },
    //MATERIA ASEGURADA
    getMaterias(id = null, reset = false) {
      // id => Identificador del Tipo de Producto
      if (!id || id === "") {
        this.banderaMateria = false;
      } else {
        this.Materias = [];
        if (reset) {
          this.form.materiaAsegurada = "";
        }
        this.loading.insuredMaterial = true;
        this.getMateria(id)
          .then((resp) => {
            this.Materias = resp;
            this.banderaMateria = true;
          })
          .finally(() => (this.loading.insuredMaterial = false));
      }
    },

    valueMateria() {
      if (this.form.producto === "") {
        const texto =
          "Debe seleccionar un Tipo de Producto para crear una nueva Materia Asegurada";
        this.snackbar = true;
        this.color_message = "error";
        this.text_Menssage = texto;
        this.icon = "mdi-cloud-alert";
      } else {
        this.state_modal_materia = true;
      }
    },

    //PAIS
    getPais() {
      this.getPaises().then((items) => {
        this.Pais = items;
      });
    },

    //MONEDA
    getMonedas() {
      this.getMoneda().then((items) => {
        this.Monedas = items;
      });
    },

    // STATUS LIST
    async getProductStatusList() {
      this.loading.status = true;
      await this.REQUEST_PRODUCT_STATUS().finally(
        () => (this.loading.status = false)
      );
    },

    //ALMACENAR VALORES PARA NEMOTECNICO
    guardarNemotecnico(item, tipo) {
      this.Ramos.map((r) => {
        if (r.ramo == this.form.ramo) {
          const data = {
            ramo: r.ramo_descripcion,
            Tproducto: item,
            TproductoID: tipo,
          };
          this.$store.commit("Nemotecnico", data);
        }
      });
    },

    // METODOS PARA CORREDOR DE SEGURO
    // Abrir listado de corredores de seguros
    handlerOpenBrokersList() {
      this.insuranceBroker.show = true;
      this.setBrokersListData();
    },
    // Seleccionar un corredor del listado
    async handlerSelectBroker(item) {
      this.insuranceBroker.selected = item;
      this.form.corredor = item._id;
      this.insuranceBroker.show = false;
    },
    // Eliminar el corredor seleccionado
    resetInsuranceBroker() {
      this.form.corredor = "";
      this.form.porcentaje_corredor = "";
      this.insuranceBroker.selected = null;
      this.insuranceBroker.filter = "";
      this.insuranceBroker.current = 1;
    },
    setSelectedBrokerData(id) {
      if (id && id !== "") {
        this.REQUEST_INSURANCE_BROKER_BY_ID(id)
          .then((response) => (this.insuranceBroker.selected = response))
          .catch((message) => {
            this.color_message = "warning";
            this.text_Menssage = message;
            this.icon = "mdi-cloud-alert";
            this.snackbar = true;
          });
      }
    },
    // Obtener el listado de corredores de seguros
    setBrokersListData() {
      this.insuranceBroker.loading = true;
      const payload = {
        limit: this.insuranceBroker.limit,
        page: this.insuranceBroker.current,
        filter: this.insuranceBroker.filter,
      };
      this.REQUEST_INSURANCE_BROKER(payload)
        .then((response) => {
          // Settear valores de la paginacion luego de la consulta
          this.insuranceBroker.pages = response.paginas;
          this.insuranceBroker.current = response.pagina;
          this.insuranceBroker.limit = response.limite;
        })
        .catch((message) => {
          this.color_message = "warning";
          this.text_Menssage = message;
          this.icon = "mdi-cloud-alert";
          this.snackbar = true;
        })
        .finally(() => (this.insuranceBroker.loading = false));
    },

    optModal(item) {
      this.valor = item;
      if (item == "financiera" || item == "otro") {
        this.state_modal_opt = true;
      }
      switch (this.tipo) {
        case 1:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.intermediario_1.valor;
          /* this.form.informacion_adicional.tarifa.intermediario_1.valor = "" */
          this.hint1 = "";
          break;
        case 2:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.taxid_homologacion.valor;
          /* this.form.informacion_adicional.tarifa.taxid_homologacion.valor = "" */
          this.hint2 = "";
          break;
        case 3:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.intermediario_2.valor;
          /* this.form.informacion_adicional.tarifa.intermediario_2.valor = "" */
          this.hint3 = "";
          break;
        case 4:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.taxid_corredor.valor;
          //
          this.hint4 = "";
          break;
        case 5:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.intermediario_3.valor;
          //this.form.informacion_adicional.tarifa.intermediario_3.valor = ""
          this.hint5 = "";
          break;
        case 6:
          this.editOpt = "";
          this.normalOpt =
            this.form.informacion_adicional.tarifa.taxid_convenio.valor;
          //this.form.informacion_adicional.tarifa.taxid_convenio.valor = ""
          this.hint6 = "";
          break;
      }
    },

    editOptions(tipo) {
      const definicion_basica =
        this.prod?.definicion_basica?.informacion_adicional?.tarifa;
      switch (tipo) {
        case 1:
          if (
            definicion_basica?.intermediario_1?.tipo == "financiera" ||
            definicion_basica?.intermediario_1?.tipo == "otro"
          ) {
            this.valor = definicion_basica.intermediario_1.tipo;
            this.editOpt = definicion_basica.intermediario_1.valor;
            this.state_modal_opt = true;
            this.tipo = 1;
          }
          break;
        case 2:
          if (
            definicion_basica?.taxid_homologacion?.tipo == "financiera" ||
            definicion_basica?.taxid_homologacion?.tipo == "otro"
          ) {
            this.valor = definicion_basica.taxid_homologacion.tipo;
            this.editOpt = definicion_basica.taxid_homologacion.valor;
            this.state_modal_opt = true;
            this.tipo = 2;
          }
          break;
        case 3:
          if (
            definicion_basica?.intermediario_2?.tipo == "financiera" ||
            definicion_basica?.intermediario_2?.tipo == "otro"
          ) {
            this.valor = definicion_basica.intermediario_2.tipo;
            this.editOpt = definicion_basica.intermediario_2.valor;
            this.state_modal_opt = true;
            this.tipo = 3;
          }
          break;
        case 4:
          if (
            definicion_basica?.taxid_corredor?.tipo == "financiera" ||
            definicion_basica?.taxid_corredor?.tipo == "otro"
          ) {
            this.valor = definicion_basica.taxid_corredor.tipo;
            this.editOpt = definicion_basica.taxid_corredor.valor;
            this.state_modal_opt = true;
            this.tipo = 4;
          }
          break;
        case 5:
          if (
            definicion_basica?.intermediario_3?.tipo == "financiera" ||
            definicion_basica?.intermediario_3?.tipo == "otro"
          ) {
            this.valor = definicion_basica.intermediario_3.tipo;
            this.editOpt = definicion_basica.intermediario_3.valor;
            this.state_modal_opt = true;
            this.tipo = 5;
          }
          break;
        case 6:
          if (
            definicion_basica?.taxid_convenio?.tipo == "financiera" ||
            definicion_basica?.taxid_convenio?.tipo == "otro"
          ) {
            this.valor = definicion_basica.taxid_convenio.tipo;
            this.editOpt = definicion_basica.taxid_convenio.valor;
            this.state_modal_opt = true;
            this.tipo = 6;
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
.father-div {
  min-height: 880px;
}

.custom-radio {
  margin-top: 1.25rem;
  width: 40%;
}
/* LOGO BOXES */
.picture-box {
  border: 2px solid var(--v-primary-base);
  height: 90px;
  overflow: hidden;
  border-bottom-width: 10px;
  cursor: pointer;
  transition: background-color 0.6s, box-shadow 0.6s;
}
.picture-box:active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
}
.picture-box.selected {
  border-color: var(--v-info-base);
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.25);
  transition: background-color 0.6s, box-shadow 0.6s;
}
.picture-box:hover {
  border-color: var(--v-info-base);
}
.picture-box,
.picture-box.selected,
.picture-box:hover {
  transition: border-color 0.2s;
}

.text-info-adicional {
  font-size: 16px;
}
</style>
