<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <!-- CAMPO SEGMENTO -->
      <v-autocomplete
        v-model="form.segmento"
        :items="getSegments"
        label="Segmento"
        :loading="loadingSegment"
        placeholder="Seleccione un segmento"
        item-text="segmento_descripcion"
        :item-value="ramoId ? '_id' : 'segmento'"
        item-disabled="eliminado"
        :disabled="!!ramoId"
        :rules="[(v) => !!v || 'Debe seleccionar un segmento.']"
      ></v-autocomplete>

      <!-- CAMPO DESCRIPCION -->
      <v-text-field
        v-model="form.tipo_producto_descripcion"
        name="Tipo"
        label="Descripción del Tipo de Producto"
        placeholder="Escriba un nuevo tipo de producto..."
        :rules="[(v) => !!v || 'El campo descripción es requerido.']"
        id="id_ramo"
      >
      </v-text-field>

      <!-- CAMPO CODIGO -->
      <v-text-field
        v-model="form.codigo"
        label="Código"
        placeholder="Escriba el código del tipo de producto"
        :rules="[(v) => !!v || 'El campo código es requerido.']"
      ></v-text-field>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TipoProductoCrudForm",
  mixins: [FormMixin],
  props: {
    ramoId: {
      type: String,
      default: "",
      description: "Id del ramo seleccionado (solo si aplica)",
    },
    segmentId: {
      type: String,
      default: "",
      description: "Id del segmento seleccionado (solo si aplica)",
    },
  },
  data() {
    return {
      defaultTitle: "Formulario tipo producto",
      form: {
        tipo_producto_descripcion: "",
        segmento: "",
        codigo: "",
      },
      localUpdate: true,
      loadingSegment: false,
    };
  },
  computed: {
    ...mapGetters("NewProduct", ["getSegments"]),
  },
  methods: {
    ...mapActions("NewProduct", [
      "requestSegment",
      "getSegmentByRamo",
      "CrearTipoProducto",
      "UpdateTipoProd",
    ]),

    setup() {
      this.loadingSegment = true;
      let segmentMethod = this.requestSegment;
      if (this.ramoId) {
        segmentMethod = this.getSegmentByRamo;
        this.form.segmento = this.segmentId;
      }
      segmentMethod(this.ramoId)
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingSegment = false;
        });
    },

    async send() {
      this.loadingForm = true;
      let method = this.CrearTipoProducto;
      const formData = { ...this.form };
      if (this.formData) {
        method = this.UpdateTipoProd;
        formData.id = this.formData.tipo_producto;
      }
      method(formData)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.$emit("submit");
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
