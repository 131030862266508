<template>
  <v-dialog
    :value="value"
    scrollable
    :persistent="loading"
    @click:outside="$emit('close')"
    max-width="500"
  >
    <v-card>
      <v-card-title primary-title>
        Formulario Materia Asegurada
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('close')"
          fab
          x-small
          text
          :disabled="loading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6" md="8">
              <v-text-field
                v-model="form.materia_descripcion"
                name="nombre-comercial"
                label="Materia Asegurada"
                placeholder="Nuevo nombre de materia"
                id="id_nombre_comercial"
                :rules="[
                  (v) => !!v || 'El campo nombre de materia es requerido.',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="form.codigo"
                name="codigo"
                label="Código"
                placeholder="Código para la materia asegurada"
                :rules="[(v) => !!v || 'El campo código es requerido.']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="submit()" :loading="loading"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MateriaAseguradaModalFormV1",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tipoProductoId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      form: {
        materia_descripcion: "",
        codigo: "",
      },
    };
  },
  methods: {
    ...mapActions("NewProduct", ["CrearMateria"]),

    submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        const formData = {
          ...this.form,
          tipo_producto: this.tipoProductoId,
        };

        this.CrearMateria(formData)
          .then(() => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: "Se ha creado la materia correctamente!",
              top: true,
              right: true,
              color: "success",
            });
            this.$emit("submit");
          })
          .catch(() => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: "Ha ocurrido un error al intentar crear una materia asegurada.",
              top: true,
              right: true,
              color: "success",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
