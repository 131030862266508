<template>
  <v-container wrap>
    <v-expansion-panels accordion focusable flat multiple v-model="panel">
      <v-expansion-panel
        v-for="(item, i) in categories"
        :key="i"
        class="mb-2"
        :id="`section-${item.ref}`"
      >
        <v-divider></v-divider>
        <v-expansion-panel-header
          color="secondary text-h6 primary--text text--darken-4"
          v-show="!('show' in item) || item.show"
        >
          {{ item.text }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container wrap class="pa-2" v-if="item.id == 51">
            <h4 class="mt-6">Ingresa los datos para la certificación</h4>
            <v-row class="mt-6">
              <v-col cols="5">
                <h4 :class="{ 'subtitle-2': mobile }">Comisión (%)</h4>
                <v-text-field
                  v-model="form.comision"
                  outlined
                  placeholder="Escribe aquí"
                ></v-text-field>
              </v-col>
              <v-col cols="7">
                <h4 :class="{ 'subtitle-2': mobile }">Canal</h4>
                <v-text-field
                  v-model="form.canal"
                  outlined
                  placeholder="Escribe aquí"
                ></v-text-field>
              </v-col>
            </v-row>

            <h4 class="mt-6">Ingresa los datos para la certificación</h4>
            <v-row class="mt-6">
              <v-col cols="5">
                <h4 :class="{ 'subtitle-2': mobile }">Tipo de Comisión (%)</h4>
                <div v-for="(item, i) in comisionTypes" :key="i">
                  <v-checkbox
                    v-model="form.comisionType"
                    class="global-check"
                    :label="item.comision_descripcion"
                    :value="item.comision_descripcion"
                    hide-details
                  />
                </div>
              </v-col>
              <v-col cols="7">
                <h4 :class="{ 'subtitle-2': mobile }">Comisión (%)</h4>
                <v-text-field
                  v-model="form.comision2"
                  outlined
                  placeholder="Escribe aquí"
                ></v-text-field>
              </v-col>
            </v-row>

            <h4 :class="{ 'mt-12': true, 'subtitle-2': mobile }">Canal</h4>
            <v-text-field
              v-model="form.canal2"
              outlined
              placeholder="Escribe aquí"
            ></v-text-field>

            <h4 :class="{ 'mt-12': true, 'subtitle-2': mobile }">
              Generar archivo de prueba <br />
              <small>(Documento, tarifas y archivos de carga)</small>
            </h4>

            <v-select
              :items="companies"
              item-text="cobertura_descripcion"
              item-value="cobertura_descripcion"
              v-model="form.company"
              label="Compañias"
              outlined
              class="mt-6"
            ></v-select>

            <div class="mt-6 text-center">
              <v-btn color="primary" outlined @click="generar()"
                >Generar archivo de prueba</v-btn
              >
              <br />
              <v-icon x-large>mdi-chevron-down</v-icon>
              <br />
              <p><v-icon>mdi-file-document-outline</v-icon> {{ fileName }}</p>
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Variable para definir actualizar el formulario",
    },
    producto: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      search: "",
      fileName: "nombre_archivo_de_carga.txt",
      form: {
        comision: "",
        comision2: "",
        comisionType: [],
        canal: "",
        canal2: "",
        company: "",
        tipo: [],
      },
      panel: [],
      companies: [],
      comisionTypes: [],

      // comisionTypes: [
      //   { label: "Uso de canal", value: "1" },
      //   { label: "Intermediación", value: "2" },
      //   { label: "Recaudación", value: "3" },
      // ],

      // companies: [
      //   { text: "Compañia 1", value: "1" },
      //   { text: "Compañia 2", value: "2" },
      //   { text: "Compañia 3", value: "3" },
      //   { text: "Compañia 4", value: "4" },
      //   { text: "Compañia 5", value: "5" },
      // ]
    };
  },
  created() {
    let counter = 0;
    this.categories.forEach(() => {
      this.panel.push(counter);
      counter++;
    });
    this.$store.commit("statePiner", true);
    this.Compañias();
    this.TipoComision();
  },
  methods: {
    ...mapActions("NewProduct", ["GetCompanies", "getTipoComision"]),
    async Compañias() {
      await this.GetCompanies().then((resp) => {
        this.companies = resp;
      });
    },
    async TipoComision() {
      await this.getTipoComision().then((resp) => {
        if (resp.status == 200) {
          this.$store.commit("statePiner", false);
          this.comisionTypes = resp.data.data.comisiones;
        }
      });
    },
  },
};
</script>

<style scoped></style>
