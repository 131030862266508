<template>
  <v-dialog
    :value="value"
    width="500"
    @click:outside="!persistent ? $emit('close') : false"
    :persistent="persistent"
  >
    <v-sheet
      color="white"
      elevation="1"
      tile
      class="pa-3"
      :min-height="small ? 100 : 300"
      style="position: relative"
    >
      <v-overlay v-if="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-btn
        v-if="closeButton"
        :disabled="persistent"
        text
        fab
        absolute
        right
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <slot />
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
